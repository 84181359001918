import React from 'react';
import ProductStateRow from "./ProductStateRow";
import _ from "lodash";
import {Link} from "react-router-dom";

function ProductAndStateRowContainer({index, patentInfo, setPatentInfo, productSelect, onClickProductDelete, onClickProductAdd}) {
    let copy = _.cloneDeep(patentInfo);

    if (patentInfo.product[index] == undefined) return null;

    return (
        <>
            <div className="row">
                <h2 className="title">제품명 {index + 1}
                    {
                        patentInfo.editing &&
                        (
                            index == 0
                                ? <button className="icon_plus" onClick={onClickProductAdd}/>
                                : <button className="icon_badge_del" onClick={() => onClickProductDelete(index)}/>
                        )
                    }
                </h2>
                {
                    patentInfo.editing
                        ?
                            <select value={patentInfo.product[index].idx}
                                    onChange={(e) => {
                                        copy.product[index].idx = e.target.value;
                                        copy.product[index].name = e.target.options[e.target.selectedIndex].text;
                                        setPatentInfo(copy);
                                    }}
                            >
                                <option value={0}>없음</option>
                                {productSelect}
                            </select>
                        :   Boolean(productSelect.length)
                            ? <p>{patentInfo.product[index].name ? patentInfo.product[index].name : <span className="color_blue">수정을 눌러 입력해 주세요.</span>}</p>
                            : <Link to="/manage/product" className="btn_mypage_product">추가하러 가기</Link>

                }
            </div>
            <ProductStateRow title="적용유무" condition={patentInfo.editing}
                             contentIdx={patentInfo.product[index].state}
                             onChange={(e) => {
                                 copy.product[index].state = e.target.value;
                                 setPatentInfo(copy);
                             }}
            />
        </>
    );
};

export default ProductAndStateRowContainer;
