import React, { useContext } from "react";
import PatentContext from "../../../store/Patent";
import DateAndFileRow from "./common/DateAndFileRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import NormalRow from "./common/NormalRow";
import InputRow from "./common/InputRow";
import typeName from "./constrant/typeName";
import { Link } from "react-router-dom";
import DatePickerRow from "./common/DatePickerRow";
import ButtonRow from "./common/ButtonRow";
import InputAndFileRow from "./common/InputAndFileRow";
import SelectRow from "./common/SelectRow";
import ClaimCountRow from "./common/ClaimCountRow";
import DivideApplyRow from "./common/DivideApplyRow";
import ProductAndStateRowContainer from "./common/ProductAndStateRowContainer";
import InventorRow from "./common/InventorRow";
import ShareRow from "./common/ShareRow";
import ProcessManage from "./common/ProcessManage";
import ProcessStep from "./common/ProcessStep";
import ButtonApprovalRow from "./common/ButtonApprovalRow";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import FormSelfCorrecting from "./FormSelfCorrecting";
import InventorInputRow from "./common/InventorInputRow";

function FormCompanyPH({
  productSelect,
  inventorSelectTag,
  jointApplyListTag,
  ListOaTag,
  agentCompanyListTag,
  managerSelectTag,
  inventorListTag,
  onClickDownPopShow,
  onClickUpPopShow,
  onChangeInput,
  onClickInventorAdd,
  onClickProductAdd,
  onChangeDatePickerInnerDate,
  onChangeClaimCount,
  onChangeDatePicker,
  onClickClaim,
  onClickFirstClaim,
  inventorSelect,
  onChangeInventorInput,
}) {
  const patentInfo = useContext(PatentContext);

  return (
    <div className="form">
      <div className="field">
        <ProcessStep text="서지 사항" state={patentInfo.state} />
        <div className="rows">
          <InputRow
            title="관리번호"
            titleClass="color_blue"
            condition={patentInfo.editing}
            content={patentInfo.manage_number}
            onChange={onChangeInput}
            setKey="manage_number"
          />
          <InputRow
            title="고객관리번호"
            condition={patentInfo.editing}
            content={patentInfo.our_manage_number}
            onChange={onChangeInput}
            setKey="our_manage_number"
          />
          <NormalRow title="유형" content={patentInfo.type} />
        </div>
        <div className="rows">
          <div className="row">
            <h2 className="title">
              Family 출원
              <Link
                className="icon_plus"
                to={`/patent/family/${patentInfo.idx}`}
              />
            </h2>
            <p>{patentInfo.family_cnt}건</p>
          </div>
        </div>
        <div className="rows">
          <SelectRow
            title="관리담당자"
            content={patentInfo.manager_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                manager_idx: e.target.value,
                manager_name: e.target.options[e.target.selectedIndex].text,
              })
            }
            conditions={patentInfo.editing}
            contentIdx={patentInfo.manager_idx}
            selectTag={managerSelectTag}
          />
          <InputRow
            title="실무담당자"
            condition={patentInfo.editing}
            content={patentInfo.ip_member_name}
            onChange={onChangeInput}
            setKey="ip_member_name"
          />
          <SelectRow
            title="현지대리인"
            content={patentInfo.local_agent_company_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                local_agent_company_idx: e.target.value,
                local_agent_company_name:
                  e.target.options[e.target.selectedIndex].text,
              })
            }
            conditions={patentInfo.editing}
            contentIdx={patentInfo.local_agent_company_idx}
            selectTag={agentCompanyListTag}
          />
        </div>
        <div className="rows">
          <InventorRow
            index={0}
            editing={patentInfo.editing}
            inventor={patentInfo.inventor[0]}
            inventorSelect={inventorSelect}
            inventorSelectTag={inventorSelectTag}
            onClickEvent={onClickInventorAdd}
          />
          <ShareRow
            index={0}
            editing={patentInfo.editing}
            inventor={patentInfo.inventor[0]}
          />
          <InventorInputRow
            title="이메일"
            condition={patentInfo.editing}
            content={patentInfo.inventor[0].email}
            onChange={onChangeInventorInput}
            index={0}
            setKey="email"
          />
        </div>
        <div className="rows" style={{ justifyContent: "flex-end" }}>
          <InventorInputRow
            title="생년월일"
            condition={patentInfo.editing}
            content={patentInfo.inventor[0].birth_date}
            onChange={onChangeInventorInput}
            index={0}
            setKey="birth_date"
          />
        </div>
        {inventorListTag}
        <div className="rows">
          <ProductAndStateRowContainer
            index={0}
            patentInfo={patentInfo}
            setPatentInfo={patentInfo.setPatent}
            productSelect={productSelect}
            onClickProductAdd={onClickProductAdd}
          />
        </div>
        {patentInfo.productListTag}
        {jointApplyListTag}
      </div>
      {patentInfo.mergeTag}
      <div className="field">
        <ProcessStep text="출원" state={patentInfo.state} />
        <div className="rows">
          <InputAndFileRow
            title="출원번호"
            titleClassName="color_blue"
            content={patentInfo.apply_number}
            haveFile={Boolean(patentInfo.apply_file_cnt)}
            onChange={(e) => onChangeInput("apply_number", e.target.value)}
            editing={patentInfo.editing}
            onClickDownPopShow={() => onClickDownPopShow(typeName.apply)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.apply)}
          />
          <DatePickerRow
            title="출원일"
            condition={patentInfo.editing}
            data={patentInfo.apply_at}
            dataKey="apply_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <NormalRow
            title="우선일"
            content={patentInfo.priority_at}
            txtType="date"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="발명의 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="영문 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name_en}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name_en"
          />
        </div>
        <div className="rows">
          <NormalRow
            title="우선심사청구"
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.priority_screen}
                toggleSelected={onClickFirstClaim}
              />
            }
          />
          <NormalRow
            title="심사청구"
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.screen_req}
                toggleSelected={onClickClaim}
              />
            }
          />
          <NormalRow
            title="심사청구 기한"
            titleClassName="color_blue"
            content={patentInfo.screen_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <DatePickerRow
            title="심사청구일"
            condition={patentInfo.editing}
            data={patentInfo.screen_req_at}
            dataKey="screen_req_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
        </div>
        <div className="process_manage">
          <ProcessManage
            title="출원 상세 절차 관리"
            selected={patentInfo.apply_detail}
          />
          {Boolean(patentInfo.apply_detail) && (
            <>
              <div className="rows">
                <ButtonRow
                  title={`번역문\n초안작성 지시`}
                  condition={patentInfo.editing}
                  data={patentInfo.draft_translation_order_at}
                  onClick={() =>
                    patentInfo.setPatent({
                      ...patentInfo,
                      draft_translation_order_at: new Date().getTime(),
                    })
                  }
                />
                <InnerDatePickerAndFileRow
                  title={"번역문\n초안 접수"}
                  data={patentInfo.translateDraftReceivingDate}
                  condition={patentInfo.editing}
                  setKey="translateDraftReceivingDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.translation_draft_file_cnt)}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_translation, {
                      modified: 0,
                    })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_translation, {
                      modified: 0,
                    })
                  }
                />
                <InnerDatePickerAndFileRow
                  title={"번역문 초안\n발명자 검토"}
                  data={patentInfo.translateDraftInventorsDate}
                  condition={patentInfo.editing}
                  setKey="translateDraftInventorsDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(
                    patentInfo.translation_draft_reply_researcher_file_cnt
                  )}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_translation_reply, {
                      type: 2,
                      modified: 0,
                    })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_translation_reply, {
                      type: 2,
                      modified: 0,
                    })
                  }
                />
              </div>
              <div className="rows">
                <div className="row no_data" />
                <div className="row no_data" />
                <InnerDatePickerAndFileRow
                  title={"번역문 초안\n담당자 검토"}
                  data={patentInfo.translateDraftManagerDate}
                  condition={patentInfo.editing}
                  setKey="translateDraftManagerDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(
                    patentInfo.translation_draft_reply_manager_file_cnt
                  )}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_translation_reply, {
                      type: 1,
                      modified: 0,
                    })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_translation_reply, {
                      type: 1,
                      modified: 0,
                    })
                  }
                />
              </div>
              <div className="rows">
                <DateAndFileRow
                  title={`번역문\n초안 수정본 전달${
                    patentInfo.draft_translation_modified_upload_at !== null
                      ? "일"
                      : ""
                  }`}
                  date={patentInfo.draft_translation_modified_upload_at}
                  haveFile={Boolean(
                    patentInfo.translation_modified_draft_file_cnt
                  )}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_translation, {
                      modified: 1,
                    })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_translation, {
                      modified: 1,
                    })
                  }
                  uploadBtnShow={true}
                />
                <InnerDatePickerAndFileRow
                  title={"번역문 초안\n최종본 접수"}
                  data={patentInfo.translateDraftFinalDate}
                  condition={patentInfo.editing}
                  setKey="translateDraftFinalDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(
                    patentInfo.translation_final_draft_file_cnt
                  )}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_translation, {
                      modified: 2,
                    })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_translation, {
                      modified: 2,
                    })
                  }
                />
                <NormalRow
                  title="번역문 기한"
                  titleClassName="color_blue"
                  content={patentInfo.translation_deadline}
                />
              </div>
              <div className="rows">
                <div className="row no_data" />
                <div className="row no_data" />
                {patentInfo.apply_approval ? (
                  <ButtonApprovalRow
                    patentInfo={patentInfo}
                    onClick={() =>
                      patentInfo.setPatentApprovalApplyPopupShow(true)
                    }
                  />
                ) : (
                  <ButtonRow
                    title="출원 지시"
                    titleClassName="color_blue"
                    condition={patentInfo.editing}
                    data={patentInfo.apply_order_at}
                    onClick={() =>
                      patentInfo.setPatent({
                        ...patentInfo,
                        apply_order_at: new Date().getTime(),
                      })
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {ListOaTag}
      <FormSelfCorrecting
        onClickDownPopShow={onClickDownPopShow}
        onClickUpPopShow={onClickUpPopShow}
        onChangeDatePicker={onChangeDatePicker}
      />
      <div className="field">
        <ProcessStep text="등록" state={patentInfo.state} />
        <div className="rows">
          <DatePickerRow
            title="등록 결정일"
            titleClassName="color_blue"
            condition={patentInfo.editing}
            data={patentInfo.register_decision_at}
            dataKey="register_decision_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <DivideApplyRow
            editingClass={patentInfo.editingClass}
            divideApplication={patentInfo.divideApplication}
            toggleSelected={() =>
              patentInfo.setPatent({
                ...patentInfo,
                divideApplication: {
                  ...patentInfo.divideApplication,
                  value: !patentInfo.divideApplication.value,
                },
              })
            }
          />
          <DatePickerRow
            title="등록일"
            condition={patentInfo.editing}
            data={patentInfo.register_at}
            dataKey="register_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
        </div>
        <div className="rows">
          <InputAndFileRow
            title="등록번호"
            content={patentInfo.register_number}
            editing={patentInfo.editing}
            haveFile={Boolean(patentInfo.register_file_cnt)}
            onChange={(e) => onChangeInput("register_number", e.target.value)}
            onClickDownPopShow={() => onClickDownPopShow(typeName.register)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.register)}
          />
          <NormalRow
            title="존속기간 만료일"
            titleClassName="color_blue"
            content={patentInfo.expiration_at}
            contentClassName="color_blue"
            txtType="date"
          />
          <NormalRow
            title="등록기한"
            titleClassName="color_blue"
            content={patentInfo.register_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <div className="row no_data" />
          <NormalRow
            title="분할 출원기한"
            titleClassName="color_blue"
            content={patentInfo.division_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
      </div>
      <div className="field">
        <ProcessStep text="연차료 정보" state={patentInfo.state} />
        <div className="rows">
          <NormalRow
            title="납부년차"
            titleClassName="color_blue"
            content={
              patentInfo.payment_year && patentInfo.payment_year + "년차"
            }
            contentClassName="color_blue"
          />
          <ClaimCountRow
            editing={patentInfo.editing}
            claimCount={patentInfo.claimCount}
            onChangeIndependence={(e) =>
              onChangeClaimCount("independence", e.target.value)
            }
            onChangeDependent={(e) =>
              onChangeClaimCount("dependent", e.target.value)
            }
          />
          <NormalRow
            title="연차료 기한"
            titleClassName="color_blue"
            content={patentInfo.annual_payment_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        {/*<div className="rows">*/}
        {/*<InputRow title="연차관리 회사" condition={patentInfo.editing} content={patentInfo.annual_payment_manage_company} onChange={onChangeInput}*/}
        {/*setKey="annual_payment_manage_company"/>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default FormCompanyPH;
