import API from "../../util/api";

export default {
  getAPI() {
    return API;
  },
  uploadTask(payload) {
    return API.post(`/admin/task/uploadExcel`, payload);
  },

  getMarketChartInfo() {
    return API.get(`/admin/mi/chartType`);
  },

  getVoucherFile(payload) {
    return API.get(`/test/dataVoucher/companyChart`, { params: payload });
  },

  getTaskDepartmentList(param) {
    return API.get("/admin/task/pdf/department", { params: param });
  },

  getResearchCategory() {
    return API.get(`/admin/research/category`);
  },

  addResearchCategory(payload) {
    return API.post(`/admin/research/category`, payload);
  },

  updateResearchCategory(category_idx, payload) {
    return API.patch(`/admin/research/category/${category_idx}`, payload);
  },

  deleteResearchCategory(category_idx) {
    return API.delete(`/admin/research/category/${category_idx}`);
  },

  updateResearchCategoryOrder(payload) {
    return API.patch(`/admin/research/category/order`, payload);
  },

  checkAuth(headers) {
    return API.get(`/member/admin_check`, headers);
  },
  getInvestorFileList(investor_idx) {
    return API.get(`/manager/company/investor/${investor_idx}/introduce`);
  },
  getRequestInvestmentList(payload) {
    return API.get("/admin/investment", { params: payload });
  },
  getFreeUsList() {
    return API.get(`/admin/ur`);
  },
  getFreeUsInfo(ur_idx) {
    return API.get(`/admin/ur/${ur_idx}`);
  },
  updateFreeUs(ur_idx, payload) {
    return API.patch(`/admin/ur/${ur_idx}`, payload);
  },
  getRequestInvestmentView(investment_idx) {
    return API.get(`/admin/investment/${investment_idx}`);
  },
  RequestInvestmentConfirm(investment_idx, payload) {
    return API.patch(`/admin/investment/${investment_idx}`, payload);
  },
  getIntroduceFileList(investment_idx) {
    return API.get(`/admin/investment/${investment_idx}/file/introduce`);
  },
  getCoreFileList(investment_idx) {
    return API.get(`/admin/investment/${investment_idx}/file/core`);
  },
  getBusinessFileList(investment_idx) {
    return API.get(`/admin/investment/${investment_idx}/file/business`);
  },
  downloadInvestorFile(investor_idx, introduce_idx) {
    window.$Global.download(
      `/manager/company/investor/${investor_idx}/introduce/${introduce_idx}/download`
    );
  },
  downloadIntroduceFile(investment_idx, introduce_idx) {
    window.$Global.download(
      `/admin/investment/${investment_idx}/file/introduce/${introduce_idx}/download`
    );
  },
  downloadCoreFile(investment_idx, core_idx) {
    window.$Global.download(
      `/admin/investment/${investment_idx}/file/core/${core_idx}/download`
    );
  },
  downloadBusinessFile(investment_idx, business_idx) {
    window.$Global.download(
      `/admin/investment/${investment_idx}/file/business/${business_idx}/download`
    );
  },
  downloadSignup() {
    window.$Global.download(`/admin/company/excel_download`);
  },

  downloadMarket() {
    return API.get(`/admin/mi/request/excel`, {
      responseType: "arraybuffer",
    }).then((res) => window.$Global.dataDownload(res));
  },

  downloadJoDal() {
    window.$Global.download(`/admin/task/download_findbiz_excel`);
  },

  addNews(payload) {
    return API.post(`/admin/news`, payload);
  },
  getNewsInfo(news_idx) {
    return API.get(`/admin/news/${news_idx}`);
  },
  updateNews(news_idx, payload) {
    return API.patch(`/admin/news/${news_idx}`, payload);
  },
  deleteNews(news_idx) {
    return API.delete(`/admin/news/${news_idx}`);
  },
  addYoutube(payload) {
    return API.post(`/admin/youtube`, payload);
  },
  getYoutubeInfo(youtube_idx) {
    return API.get(`/admin/youtube/${youtube_idx}`);
  },
  updateYoutube(youtube_idx, payload) {
    return API.patch(`/admin/youtube/${youtube_idx}`, payload);
  },
  deleteYoutube(news_idx) {
    return API.delete(`/admin/youtube/${news_idx}`);
  },
  getSignInfoList(payload) {
    return API.get(`/admin/company`, { params: payload });
  },
  addNotify(payload) {
    return API.post(`/admin/notice`, payload);
  },
  addTutorial(payload) {
    return API.post(`/admin/tutorial`, payload);
  },
  updateNotify(notice_idx, payload) {
    return API.patch(`/admin/notice/${notice_idx}`, payload);
  },
  addQna(payload) {
    return API.post(`/admin/qna`, payload);
  },
  updateQna(notice_idx, payload) {
    return API.patch(`/admin/qna/${notice_idx}`, payload);
  },
  uploadNotifyFile(notice_idx, payload) {
    return API.post(`/admin/notice/${notice_idx}/file`, payload);
  },
  deleteNotify(notice_idx) {
    return API.delete(`/admin/notice/${notice_idx}`);
  },
  getNotifyFileList(notice_idx) {
    return API.get(`/admin/notice/${notice_idx}/file`);
  },
  deleteNotifyFile(notice_idx, file_idx) {
    return API.delete(`/admin/notice/${notice_idx}/file/${file_idx}`);
  },
  uploadQnaFile(qna_idx, payload) {
    return API.post(`/admin/qna/${qna_idx}/file`, payload);
  },
  deleteQna(qna_idx) {
    return API.delete(`/admin/qna/${qna_idx}`);
  },
  getQnaFileList(qna_idx) {
    return API.get(`/admin/qna/${qna_idx}/file`);
  },
  deleteQnaFile(qna_idx, file_idx) {
    return API.delete(`/admin/qna/${qna_idx}/file/${file_idx}`);
  },
  addEdu(payload) {
    return API.post(`/admin/edu`, payload);
  },
  updateTutorial(tutorial_idx, payload) {
    return API.patch(`/admin//tutorial/${tutorial_idx}`, payload);
  },
  updateEdu(edu_idx, payload) {
    return API.patch(`/admin/edu/${edu_idx}`, payload);
  },
  uploadEduFile(edu_idx, payload) {
    return API.post(`/admin/edu/${edu_idx}/file`, payload);
  },
  deleteEdu(edu_idx) {
    return API.delete(`/admin/edu/${edu_idx}`);
  },
  deletetutorial(tutorial_idx) {
    return API.delete(`/admin//tutorial/${tutorial_idx}`);
  },
  getTutorialFileList(tutorial_idx) {
    return API.get(`/admin//tutorial/${tutorial_idx}/file`);
  },
  deleteTutorialFile(tutorial_idx, file_idx) {
    return API.delete(`/admin/tutorial/${tutorial_idx}/file/${file_idx}`);
  },
  uploadImage(payload) {
    return API.post(`/admin/upload/media`, payload);
  },
  uploadImageTrim(payload) {
    return API.post(`/admin/upload/media/trim`, payload);
  },
  stopMember(company_idx) {
    return API.delete(`/admin/company/${company_idx}`);
  },
  deleteMember(company_idx) {
    return API.delete(`/admin/company/${company_idx}/force`);
  },
  recoverMember(company_idx) {
    return API.patch(`/admin/company/${company_idx}/recover`);
  },
  downloadFreeUsFile(ur_idx, file_idx) {
    window.$Global.download(`/admin/ur/${ur_idx}/file/${file_idx}/download`);
  },
  getTaskField() {
    return API.get("/admin/task/field");
  },
  getTaskList(payload, data) {
    return API.post("/admin/task/select", data, { params: payload });
  },
  getTaskInfo(idx) {
    return API.get(`/admin/task/${idx}`);
  },
  addTask(payload) {
    return API.post("/admin/task", payload);
  },
  updateTask(idx, payload) {
    return API.patch(`/admin/task/${idx}`, payload);
  },
  updateTaskAdditional(idx, payload) {
    return API.patch(`/admin/task/${idx}/additional`, payload);
  },
  deleteTask(idx) {
    return API.delete(`/admin/task/${idx}`);
  },

  deleteTaskFile(task_idx, file_idx) {
    return API.delete(`/admin/task/${task_idx}/file/${file_idx}`);
  },

  getCommunityCategory() {
    return API.get("/admin/notice/category");
  },

  getCommunityInfo(notice_idx) {
    return API.get(`/admin/notice/${notice_idx}`);
  },

  getCommunityList(payload) {
    return API.get("/admin/notice", { params: payload });
  },

  getCommunityPin(notice_idx) {
    return API.post(`/admin/notice/${notice_idx}/pin`);
  },

  downloadCompanyListExcel(payload) {
    return API.post(`/admin/company/download_annual_pay_info`, payload, {
      responseType: "arraybuffer",
    });
  },

  downloadMailImage(payload) {
    return API.post("admin/mailing/invite", payload, {
      responseType: "arraybuffer",
    });
  },

  getMailing(company_code) {
    return API.post(`/admin/mailing/invite`, { company_code: company_code });
  },

  getMailingUser(company_code) {
    return API.post(`/common/dummy/company/statistic`, {
      company_code: company_code,
    });
  },

  getDailyStatistic(params) {
    return API.get(`/admin/log/visit/daily_statistic`, { params: params });
  },

  postAnnualPay(payload) {
    return API.post(`/admin/dummy/company/annual_pay`, payload);
  },

  getAdminMngCompanyAnnual(params) {
    return API.get(`/admin/company/annualMngPay`, { params: params });
  },

  getAnnulPay(params) {
    return API.get("/admin/dummy/company/annual_pay", { params: params });
  },

  deleteAnnualPay(ap_idx) {
    return API.delete(`/admin/dummy/company/annual_pay/${ap_idx}`);
  },

  getMarketCategory() {
    return API.get("/admin/mi/category");
  },

  addMarketCategory(payload) {
    return API.post("/admin/mi/category", payload);
  },

  updateMarketCategory(category_idx, payload) {
    return API.patch(`/admin/mi/category/${category_idx}`, payload);
  },

  updateMarketCategoryOrder(payload) {
    return API.patch(`/admin/mi/category/order`, payload);
  },

  deleteMarketCategory(category_idx) {
    return API.delete(`/admin/mi/category/${category_idx}`);
  },

  addMarketInfo(payload) {
    return API.post("/admin/mi", payload);
  },

  updateMarketInfo(mi_idx, payload) {
    return API.patch(`/admin/mi/${mi_idx}`, payload);
  },

  getMarketInfo(mi_idx) {
    return API.get(`/admin/mi/${mi_idx}`);
  },

  getMarketList(params) {
    return API.get("/admin/mi", { params: params });
  },

  deleteMarket(mi_idx) {
    return API.delete(`/admin/mi/${mi_idx}`);
  },

  toggleTaskRecommend(task_idx) {
    return API.patch(`/admin/task/${task_idx}/recommanded`);
  },

  addTaskPDF(payload) {
    return API.post("/admin/task/pdf", payload);
  },

  getTaskPDFList() {
    return API.get("/admin/task/pdf");
  },

  updateTaskPDF(pdf_idx, payload) {
    return API.patch(`/admin/task/pdf/${pdf_idx}`, payload);
  },

  deleteTaskPDF(pdf_idx) {
    return API.delete(`/admin/task/pdf/${pdf_idx}`);
  },

  getResearchList(param) {
    return API.get(`/admin/research`, { params: param });
  },

  addResearch(payload) {
    return API.post("/admin/research", payload);
  },

  deleteResearch(research_idx) {
    return API.delete(`/admin/research/${research_idx}`);
  },

  getAlbaList() {
    return API.get("/admin/alba/list");
  },

  getDummyAccount(headers) {
    return API.get("/admin/member/dummy", headers);
  },

  addDummyAccount(payload, headers) {
    return API.post("/admin/member/dummy", payload, headers);
  },

  updateDummyAccount(idx, payload, headers) {
    return API.patch(`/admin/member/dummy/${idx}`, payload, headers);
  },

  deleteDummyAccount(idx, headers) {
    return API.delete(`/admin/member/dummy/${idx}`, headers);
  },

  getAdminCompanyAnnual() {
    return API.get(`/admin/company/annualPay`);
  },

  getAdminCompanyPatentList(company_idx, payload) {
    return API.get(`/admin/company/${company_idx}/patent/annualPay`, {
      params: payload,
    });
  },

  getTest(company_idx, payload) {
    return API.post(
      `/admin/company/${company_idx}/patent/annualPay/test`,
      payload
    );
  },

  getAdminCompanyTradeMarkList(company_idx, payload) {
    return API.get(`/admin/company/${company_idx}/trademark/annualPay`, {
      params: payload,
    });
  },

  getAdminCompanyDesignList(company_idx, payload) {
    return API.get(`/admin/company/${company_idx}/design/annualPay`, {
      params: payload,
    });
  },

  getAdminPatentAnnualPay(company_idx, annual_pay_idx) {
    return API.get(
      `/admin/company/${company_idx}/patent/annualPay/${annual_pay_idx}`
    );
  },

  updateAdminPaymentCompleted(company_idx, annual_pay_idx, type) {
    return API.patch(
      `/admin/company/${company_idx}/${type}/annualPay/${annual_pay_idx}/paymentCompleted`
    );
  },

  updateAdminPaymentAllCompleted(payload) {
    return API.patch(
      `/admin/company/patent/annualPay/AllPaymentCompleted`,
      payload
    );
  },

  getAdminTradeMarkAnnualPay(company_idx, annual_pay_idx) {
    return API.get(
      `/admin/company/${company_idx}/trademark/annualPay/${annual_pay_idx}`
    );
  },

  getAdminDesignAnnualPay(company_idx, annual_pay_idx) {
    return API.get(
      `/admin/company/${company_idx}/design/annualPay/${annual_pay_idx}`
    );
  },

  getAdminPatentReceiptData(company_idx, annual_pay_idx) {
    return API.get(
      `/admin/company/${company_idx}/patent/annualPay/${annual_pay_idx}/billing`
    );
  },

  getAdminTradeMarkReceiptData(company_idx, annual_pay_idx) {
    return API.get(
      `/admin/company/${company_idx}/trademark/annualPay/${annual_pay_idx}/billing`
    );
  },

  getAdminDesignReceiptData(company_idx, annual_pay_idx) {
    return API.get(
      `/admin/company/${company_idx}/design/annualPay/${annual_pay_idx}/billing`
    );
  },

  uploadAdminPatentReceiptData(company_idx, annual_pay_idx, data) {
    return API.post(
      `/admin/company/${company_idx}/patent/annualPay/${annual_pay_idx}/billing`,
      data
    );
  },

  uploadAdminTradeMarkReceiptData(company_idx, annual_pay_idx, data) {
    return API.post(
      `/admin/company/${company_idx}/trademark/annualPay/${annual_pay_idx}/billing`,
      data
    );
  },

  uploadAdminDesignReceiptData(company_idx, annual_pay_idx, data) {
    return API.post(
      `/admin/company/${company_idx}/design/annualPay/${annual_pay_idx}/billing`,
      data
    );
  },

  deleteAdminPatentReceiptFile(company_idx, annual_pay_idx, file_idx) {
    return API.delete(`/admin/company/${company_idx}/patent/annualPay/${annual_pay_idx}
/billing/file/${file_idx}`);
  },

  deleteAdminTradeMarkReceiptFile(company_idx, annual_pay_idx, file_idx) {
    return API.delete(`/admin/company/${company_idx}/trademark/annualPay/${annual_pay_idx}
/billing/file/${file_idx}`);
  },

  downloadPatentFile(company_idx, annual_pay_idx, file_idx) {
    return API.post(`/admin/company/${company_idx}/patent/
annualPay/${annual_pay_idx}/file/${file_idx}/download`);
  },

  downloadTradeMarkFile(company_idx, annual_pay_idx, file_idx) {
    return API.post(`/admin/company/${company_idx}/trademark/
annualPay/${annual_pay_idx}/file/${file_idx}/download`);
  },
  // 고객사 회사 정보
  getAdminCompanyInfo(company_idx) {
    return API.get(`/admin/company/${company_idx}`);
  },
  getAdminCompanyPatentYearList(company_idx, payload) {
    return API.get(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_year`,
      { params: payload }
    );
  },
  getAdminCompanyInventorAllList(company_idx, payload) {
    return API.get(`/admin/inventor/`, { params: payload });
  },

  getInventorInfo(idx) {
    return API.get(`/admin/inventor/${idx}`);
  },

  updateInventorInfo(idx, payload) {
    return API.patch(`/admin/inventor/${idx}`, payload);
  },

  getAdminCompanyGiveUpList(payload) {
    return API.get(`/admin/inventor/giveUp`, { params: payload });
  },

  getAdminInventorList(payload) {
    return API.get(`/admin/inventor/inventorUserList`, { params: payload });
  },

  getPatentInventorList(params) {
    return API.get("/admin/inventor/patentInventorList", { params: params });
  },

  getInventorPatentList(params) {
    return API.get(`/admin/inventor/patent/list/`, { params: params });
  },
  // 연차료 비교 다른값 Post
  annualPayDiff(company_idx, payload) {
    return API.post(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_diff`,
      payload
    );
  },
  // 연차료 엑셀다운
  annualPayexcel(company_idx, payload) {
    window.$Global.download_get(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_excel`,
      { params: payload }
    );
  },

  isPaid(payload) {
    return API.get(`/common/inventor/inventor_isPaid`, {
      params: { aesq: payload.aesp },
    });
  },

  isGiveup(payload) {
    return API.get(`/common/inventor/inventor_isGiveup`, {
      params: { aesq: payload.aesp },
    });
  },

  check_birthday(payload) {
    return API.post(`/common/inventor/inventor_check_birthday`, payload);
  },

  confirm_IsPaid(payload) {
    return API.post(`/common/inventor/confirm_IsPaid`, payload);
  },

  confirm_IsGiveup(payload) {
    return API.post(`/common/inventor/confirm_IsGiveup`, payload);
  },

  confirm_IsRecipt(payload) {
    return API.post(`/common/inventor/isrecept`, payload);
  },

  annualPayComment(company_idx, payload) {
    return API.post(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_comment`,
      payload
    );
  },

  annualPayFinalComment(company_idx, payload) {
    return API.post(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_final_comment`,
      payload
    );
  },

  annualPayUserComment(company_idx, payload) {
    return API.post(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_user_comment`,
      payload
    );
  },

  annualPayExcelUpload(company_idx, payload) {
    return API.post(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_excelUpload`,
      payload
    );
  },

  annualPayDeleteItem(company_idx, payload) {
    return API.post(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_delete`,
      payload
    );
  },

  getAllmailingFormIdx() {
    return API.get(`/manager/inventor/all_mailingFormIdx`);
  },

  getMailingFormIdxByIdx(payload) {
    return API.get(`/manager/inventor/mailingFormIdxByIdx`, {
      params: {
        idx: payload,
      },
    });
  },

  sendMailList(payload) {
    return API.get(`/manager/inventor/sendmailList`, {
      params: payload,
    });
  },

  annualPayUserDataComment(company_idx, payload) {
    return API.patch(
      `/admin/company/${company_idx}/patent/annualPay/annual_pay_right_comment`,
      payload
    );
  },

  updateAnnualPerCostData(payload) {
    return API.post("/admin/company/patent/annualPay/updateData", payload);
  },

  // 포기권 되돌리기 (일단 특허만)
  revertGiveUp(company_idx, annual_pay_idx) {
    return API.patch(
      `/admin/company/${company_idx}/patent/annualPay/${annual_pay_idx}/revertGiveUp`
    );
  },
};
