import { useContext } from "react";
import TradeMarkContext from "../../store/TradeMark";
import "./css/NationListPopUp.scss";
import { tradeMarkCountryKr } from "../../types/nationType";

const NationListPopUp = ({
  nationListArr,
  setNationListArr,
  setIsNationPopupClick,
}) => {
  const tradeMark = useContext(TradeMarkContext);

  return (
    <div id="NationListPopUp">
      <header>
        <h2>지역 선택</h2>
        <i className="icon_exit" onClick={() => setIsNationPopupClick(false)} />
      </header>
      <div className="nation_list_wrapper">
        <ul>
          {nationListArr?.map((item) => {
            return (
              <li
                className="nation_list_first"
                onClick={() => {
                  item.isOpen = !item.isOpen;
                  setNationListArr([...nationListArr]);
                }}
              >
                <p>
                  <i
                    className={
                      item.isOpen
                        ? "icon_arrow_down_gray"
                        : "icon_arrow_right_s"
                    }
                  />
                  <span>{item.name}</span>
                  {window.$Global.convertCountryName(item.name)}
                </p>
                {item.isOpen && (
                  <ul>
                    {item.nationList?.map((subItem) => {
                      return (
                        <li
                          className="nation_list_second"
                          onClick={() => {
                            tradeMark.setTradeMark({
                              ...tradeMark,
                              nation: Object.values(subItem)[0],
                              nation_string: Object.keys(subItem)[0],
                            });
                            setIsNationPopupClick(false);
                          }}
                        >
                          <p>
                            <i className="icon_arrow_right_s" />
                            <span>{Object.keys(subItem)[0]}</span>

                            {window.$Global.convertNationName(
                              Object.values(subItem)
                            )}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default NationListPopUp;
