import React from 'react';

function InputAndFileRow({rowClassName, title, titleClassName, inputWidth, editing, content, onChange, haveFile, onClickDownPopShow, onClickUpPopShow}) {
    return (
        <div className={`row ${rowClassName || ''}`}>
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}<i className="icon_info" data-content={window.$Global.getInfoMessage(title)}/></h2>
            <div className="btns">
                {
                    editing
                        ? <input type="text" value={content || undefined} onChange={onChange} style={{width: inputWidth}}/>
                        : <p>{content ? content : 'N/A'}</p>
                }
                <button className={haveFile ? 'icon_download_attached' : 'icon_download'} onClick={onClickDownPopShow}/>
                <button className="icon_upload" onClick={onClickUpPopShow}/>
            </div>
        </div>
    );
}

export default InputAndFileRow;
