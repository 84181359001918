import { useHistory, useLocation, useParams } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import MemberContext from "../../../store/Member";
import "./css/AdminAnnualFeeList.scss";
import AdminAPI from "../../../API/admin";
import Pagination from "../../common/Pagination";
import AnnualFeeReview from "../AnnualFeeReview";
import AnnualFeeReviewSecond from "../AnnualFeeReviewSecond";
import AnnualFeeReceipt from "../AnnualFeeReceipt";
import PaymentState from "../PaymentState";
import AnnualFeeBilling from "../AnnualFeeBilling";
import { payStateList } from "../../../types/patentCostType";
import AnnualFeeReviewState from "../AnnualFeeReviewState";
import DatePicker from "react-datepicker";
import AnnualFeePerCost from "../AnnualFeePerCost";

const tHeadStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

const searchSelectOption = [
  {
    key: 0,
    name: "출원번호",
    value: "출원번호",
  },
  {
    key: 1,
    name: "등록번호",
    value: "등록번호",
  },
  {
    key: 2,
    name: "관리번호",
    value: "관리번호",
  },
];

const AdminAnnualFeeList = () => {
  const history = useHistory();
  const location = useLocation();
  const { company_idx } = useParams();
  const { company_name } = useContext(MemberContext);
  const [patentTag, setPatentTag] = useState([]);
  const [tradeMarkTag, setTradeMarkTag] = useState([]);
  const [showPatent, setShowPatent] = useState(
    window.sessionStorage.getItem("adminShowPatent") || "patent"
  );
  const [curPage, setCurPage] = useState(
    window.sessionStorage.getItem("adminCurPage") || 1
  );
  const [data, setData] = useState([]);
  const [count, setCount] = useState(10);
  const [last, setLast] = useState(1);
  const [paymentYear, setPaymentYear] = useState("");
  const [companyInfo, setCompanyInfo] = useState({});
  const [patentNationArr, setPatentNationArr] = useState([]);
  const [sortType, setSortType] = useState(0);
  const [payState, setPayState] = useState("");
  const [reviewState, setReviewState] = useState(0);
  const [payStateArr, setPayStateArr] = useState([]);
  const [showPopup, setShowPopup] = useState({
    first: false,
    second: false,
    receipt: false,
    billing: false,
  });
  const [annualFeeData, setAnnualFeeData] = useState({
    manage_number: "",
    register_number: "",
    annual_pay_idx: "",
    patent_idx: "",
    trademark_idx: "",
    design_idx: "",
    billing_idx: null,
    nation: null,
    payment_year: null,
    company_name: "",
  });
  const [selectNation, setSelectNation] = useState({
    patent_nation: "",
  });

  const nowYear = new Date().getFullYear();
  const nowMonth = new Date().getMonth() + 1;
  const [currentYear, setCurrentYear] = useState(
    window.sessionStorage.getItem("adminCurrentYear") || nowYear
  );
  const [month, setMonth] = useState(
    window.sessionStorage.getItem("adminMonth") || nowMonth
  );
  const [showSelect, setShowSelect] = useState({
    nation: false,
    annualFee: false,
    review: false,
  });

  const [calenderOpen, setCalenderOpen] = useState(false);
  const [calenderDate, setCalenderDate] = useState(null);

  const [isShowPerCost, setIsShowPerCost] = useState(false); // 관리주체/비용분담률 On/Off State
  const [paymentToggle, setPaymentToggle] = useState(false); // 연차료 일괄납부 토글
  const [paymentList, setPaymentList] = useState([]); // 연차료 일괄납부 저장 변수

  const [searchOption, setSearchOption] = useState(0); // 검색 옵션 확인용
  const [searchKeyword, setSearchKeyword] = useState(""); // 검색 키워드

  // 0 납부기한 내림차순
  // 1 납부기한 오름차순
  // 2 관리번호 내림차순
  // 3 관리번호 오름차순
  // 4 출원일 내림차순
  // 5 출원일 오름차순
  const obj_sort = {
    sort_deadline_down: 0,
    sort_deadline_up: 1,
    sort_manage_down: 2,
    sort_manage_up: 3,
    sort_apply_down: 4,
    sort_apply_up: 5,
  };

  window.sessionStorage.setItem("adminCurPage", curPage);
  window.sessionStorage.setItem("adminShowPatent", showPatent);
  window.sessionStorage.setItem("adminCurrentYear", currentYear);
  window.sessionStorage.setItem("adminMonth", month);

  useEffect(() => {
    getCompanyInfo(company_idx);
  }, []);

  useEffect(() => {
    setPaymentToggle(false);
  }, [showPatent]);

  useEffect(() => {
    switch (showPatent) {
      case "patent":
        getPatentList();
        break;
      case "trademark":
        getTradeMarkList();
        break;
      // 여기에 다른 탭에 대한 조건을 추가할 수 있습니다.
      case "design":
        getDesignList();
        break;
      default:
        getPatentList();
        break;
    }
  }, [
    showPatent,
    curPage,
    currentYear,
    month,
    selectNation,
    sortType,
    payState,
    reviewState,
    count,
    calenderDate,
    paymentToggle,
  ]);

  const getCompanyInfo = async (company_idx) => {
    let companyData = await AdminAPI.getAdminCompanyInfo(company_idx).then(
      (res) => res.data
    );
    setCompanyInfo(companyData);
    setAnnualFeeData({ ...annualFeeData, company_name: companyData.name });
  };

  const AnnualPayReceipt = ({ item }) => {
    return item.billing_idx !== null ? (
      <div className="annual_receipt">
        <p>비용청구</p>
        <button
          onClick={() => {
            let data = {
              annual_pay_idx: item.idx,
              register_number: item.register_number,
              patent_idx: company_idx,
              trademark_idx: company_idx,
              design_idx: company_idx,
              billing_idx: item.billing_idx,
              nation: item.nation_code,
              cost: item.cost,
              payment_year: item.payment_year,
            };

            switch (showPatent) {
              case "patent":
                data.patent_idx = company_idx;
                break;
              case "trademark":
                data.trademark_idx = company_idx;
                break;
              case "design":
                data.design_idx = company_idx;
                break;
            }

            setAnnualFeeData(data);
            console.log(showPopup);
            setShowPopup({ ...showPopup, billing: false, receipt: true });

            /*setAnnualFeeData({
              annual_pay_idx: item.idx,
              ...(showPatent
                ? { patent_idx: company_idx }
                : { trademark_idx: company_idx }),
            });
            setShowPopup({ ...showPopup, receipt: true });*/
          }}
        >
          영수증확인하기
        </button>
      </div>
    ) : (
      <p>-</p>
    );
  };

  const getPatentList = () => {
    let payload = {
      year: currentYear,
      month: month,
      count: count,
      page: curPage,
      nation: selectNation.patent_nation,
      sortType: sortType,
      payment_state: payState,
      review_state: reviewState,
      ...(calenderDate && {
        application_date: window.$Global.convertDateNotChar(calenderDate),
      }),
      keyword: searchKeyword, // 검색 키워드
      option: searchOption, // 출원번호, 관리번호, 등록번호 등 검색 옵션
    };
    AdminAPI.getAdminCompanyPatentList(company_idx, payload).then((res) => {
      setLast(res.data.last);
      setData(res.data.items);
      patentNationArr.length === 0 && setPatentNationArr(res.data.nationRange);
      payStateArr.length === 0 && setPayStateArr(res.data.payState);
    });
  };

  const getTradeMarkList = () => {
    let payload = {
      year: currentYear,
      month: month,
      count: count,
      page: curPage,
      nation: selectNation.patent_nation,
      sortType: sortType,
      payment_state: payState,
      review_state: reviewState,
    };
    AdminAPI.getAdminCompanyTradeMarkList(company_idx, payload).then((res) => {
      setLast(res.data.last);
      setData(res.data.items);
    });
  };

  const getDesignList = () => {
    let payload = {
      year: currentYear,
      month: month,
      count: count,
      page: curPage,
      nation: selectNation.patent_nation,
      sortType: sortType,
      payment_state: payState,
      review_state: reviewState,
    };
    AdminAPI.getAdminCompanyDesignList(company_idx, payload).then((res) => {
      setLast(res.data.last);
      setData(res.data.items);
    });
  };

  const onChangeNation = (type) => {
    setSelectNation({ ...selectNation, patent_nation: type });
    setCurPage(1);

    initSelectShow();
  };

  const onChangeYear = (e) => {
    setCurPage(1);
    setPatentNationArr([]);
    setPayStateArr([]);
    setCurrentYear(Number(e.target.value));
  };

  const onChangeMonth = (e) => {
    setCurPage(1);
    setPatentNationArr([]);
    setPayStateArr([]);
    setMonth(Number(e.target.value));
  };

  const onChangeSortType = (e) => {
    //setCurPage(1);
    setSortType(e);
  };

  const onChangePayState = (type) => {
    setCurPage(1);
    setPayState(type);
    initSelectShow();
  };

  const onChangeReviewState = (type) => {
    setCurPage(1);
    setReviewState(type);
    initSelectShow();
  };

  const initSelectShow = () => {
    setShowSelect({ nation: false, annualFee: false, review: false });
  };

  const handleAllComplete = () => {
    AdminAPI.updateAdminPaymentAllCompleted({
      annualPayIdxArray: paymentList,
    }).then((res) => {
      alert("납부완료되었습니다.");
      switch (showPatent) {
        case "patent":
          getPatentList();
          break;
        case "trademark":
          getTradeMarkList();
          break;
        case "design":
          getDesignList();
          break;
        default:
          getPatentList();
          break;
      }
      setPaymentToggle(false);
    });
  };

  const handleComplete = (idx) => {
    AdminAPI.updateAdminPaymentCompleted(company_idx, idx, showPatent).then(
      (res) => {
        alert("납부완료 되었습니다.");
        switch (showPatent) {
          case "patent":
            getPatentList();
            break;
          case "trademark":
            getTradeMarkList();
            break;
          case "design":
            getDesignList();
            break;
          default:
            getPatentList();
            break;
        }
      }
    );
  };

  const handleSearch = () => {
    switch (showPatent) {
      case "patent":
        getPatentList();
        break;
      case "trademark":
        getTradeMarkList();
        break;
      case "design":
        getDesignList();
        break;
      default:
        getPatentList();
        break;
    }
  };

  const handlePaymentToggle = (type) => {
    if (type === "cancel") {
      if (
        window.confirm("취소하시겠습니까? 체크했던 건들은 저장되지 않습니다.")
      ) {
        setPaymentToggle(false);
        setPaymentList([]);
      }
    } else if (type === "save") {
    }
  };

  const handleCheckboxChange = (e, el) => {
    if (e.target.checked) {
      setPaymentList([...paymentList, el.idx]);
    } else {
      const newPaymentList = paymentList.filter((item) => item.idx !== el);
      setPaymentList(newPaymentList);
    }
  };

  return (
    <div id="AdminAnnualFeeList">
      <div className="list_header">
        <p>
          “{companyInfo.name}”의 {currentYear}년 {month}월 연차료 리스트
        </p>
        <div>
          <button
            className="search_inventor"
            onClick={() =>
              history.push({
                pathname: "/admin/inventor/list",
                state: { company_idx: company_idx },
              })
            }
          >
            전체 발명자 찾기
          </button>
          <button
            onClick={() =>
              history.push({
                pathname: "/admin/annual/giveup/list",
                state: { company_idx: company_idx },
              })
            }
            className="search_inventor"
          >
            포기권 모아보기
          </button>
          <button
            className="btn_search_name"
            onClick={() =>
              history.push({
                pathname: `/admin/annual/compare`,
                state: {
                  company_idx,
                  year: currentYear,
                  month,
                },
              })
            }
          >
            연차료 비교
          </button>
          <button
            onClick={() => {
              window.sessionStorage.removeItem("adminCurPage");
              window.sessionStorage.removeItem("adminShowPatent");
              window.sessionStorage.removeItem("adminCurrentYear");
              window.sessionStorage.removeItem("adminMonth");
              history.push("/admin/annual");
            }}
          >
            목록으로 돌아가기
          </button>
        </div>
      </div>
      <div className="button_wrapper">
        <button
          className="search_inventor"
          onClick={() =>
            history.push({
              pathname: "/annual/list/review",
              state: { company_idx: company_idx, year: currentYear, month },
            })
          }
        >
          검토/포기 모아보기
        </button>
        {paymentToggle ? (
          <div>
            <button onClick={() => handleAllComplete()}>저장</button>
            <button onClick={() => handlePaymentToggle("cancel")}>취소</button>
          </div>
        ) : (
          <button onClick={() => setPaymentToggle(true)}>
            연차료 일괄납부
          </button>
        )}
      </div>
      <div className="select_box_wrapper">
        <div className="select_wrapper">
          <p>연월 변경</p>
          <select value={currentYear} onChange={(e) => onChangeYear(e)}>
            {Array.from({ length: 26 }, (_, i) => nowYear - 10 + i)
              .reverse()
              .map((y) => (
                <option key={y} value={y}>
                  {y}년
                </option>
              ))}
          </select>
          <select value={month} onChange={(e) => onChangeMonth(e)}>
            {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
              <option key={m} value={m}>
                {m}월
              </option>
            ))}
          </select>
        </div>
        <div className="select_box_right">
          <div className="search_wrapper">
            <select onChange={(e) => setSearchOption(e.target.value)}>
              {searchSelectOption.map((item) => {
                return (
                  <option key={item.key} value={item.key} name={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <input
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && handleSearch()}
            />
            <i className="icon_search_blue" onClick={() => handleSearch()} />
          </div>
          <div className="select_wrapper">
            <p>리스트 개수</p>
            <select
              onChange={(e) => {
                setCount(e.target.value);
                setCurPage(1);
              }}
            >
              <option value={10}>10개</option>
              <option value={50}>50개</option>
              <option value={100}>100개</option>
            </select>
          </div>
        </div>
      </div>
      <div className="annual_change_view">
        <p
          className="table_title"
          style={{
            borderBottom: showPatent === "patent" && "3px solid #4593f5",
          }}
          onClick={() => {
            setCurPage(1);
            setShowPatent("patent");
          }}
        >
          특&nbsp; 허
        </p>
        <p
          className="table_title"
          style={{
            borderBottom: showPatent === "trademark" && "3px solid #4593f5",
          }}
          onClick={() => {
            setCurPage(1);
            setShowPatent("trademark");
          }}
        >
          상&nbsp; 표
        </p>
        <p
          className="table_title"
          style={{
            borderBottom: showPatent === "design" && "3px solid #4593f5",
          }}
          onClick={() => {
            setShowPatent("design");
            setCurPage(1);
          }}
        >
          디 자 인
        </p>
      </div>
      <div className="annual_list_table">
        <table>
          <colgroup>
            {paymentToggle && <col width={50} />}
            <col width={60} />
            <col width={120} />
            <col width={70} />
            <col width={110} />
            <col width={110} />
            <col width={110} />
            <col width={110} />
            <col width={90} />
            <col width={70} />
            <col width={70} />
            <col width={110} />
            {showPatent === "patent" && <col width={150} />}
            <col width={100} />
            <col width={70} />
            <col width={120} />
            <col width={90} />
            <col width={100} />
            <col width={150} />
          </colgroup>
          <thead>
            <tr>
              {paymentToggle && <th></th>}
              <th>건 수</th>
              <th style={{ ...tHeadStyle }}>
                <p>관리번호</p>
                <button
                  className={
                    sortType == obj_sort.sort_manage_down
                      ? "icon_arrow_up_gray"
                      : "icon_arrow_down_gray"
                  }
                  onClick={() =>
                    onChangeSortType(
                      sortType == obj_sort.sort_manage_down
                        ? obj_sort.sort_manage_up
                        : obj_sort.sort_manage_down
                    )
                  }
                />
              </th>
              <th>
                <p className="select_text">
                  국 가{" "}
                  <i
                    className="icon_arrow_down_gray"
                    onClick={() => {
                      showSelect.nation = !showSelect.nation;
                      setShowSelect({
                        ...showSelect,
                        annualFee: false,
                        review: false,
                      });
                    }}
                  />
                </p>
                {showSelect.nation && (
                  <ul className="nation_list">
                    <li onClick={() => onChangeNation("")}>전 체</li>
                    {patentNationArr?.map((el) => {
                      return (
                        <li onClick={() => onChangeNation(el.nation)}>
                          {window.$Global.convertNationCodeToStr(el.nation)}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </th>
              <th>출원번호</th>
              <th style={{ ...tHeadStyle }}>
                <p>출원일</p>
                <button
                  className={
                    sortType == obj_sort.sort_apply_down
                      ? "icon_arrow_up_gray"
                      : "icon_arrow_down_gray"
                  }
                  onClick={() =>
                    onChangeSortType(
                      sortType == obj_sort.sort_apply_down
                        ? obj_sort.sort_apply_up
                        : obj_sort.sort_apply_down
                    )
                  }
                />
              </th>
              <th>등록번호</th>
              <th>등록일</th>
              <th style={{ ...tHeadStyle }}>
                <p>납부기한</p>
                <button
                  className={
                    sortType == obj_sort.sort_deadline_down
                      ? "icon_arrow_up_gray"
                      : "icon_arrow_down_gray"
                  }
                  onClick={() =>
                    onChangeSortType(
                      sortType == obj_sort.sort_deadline_down
                        ? obj_sort.sort_deadline_up
                        : obj_sort.sort_deadline_down
                    )
                  }
                />
              </th>
              <th>납부연차</th>
              <th>청구항</th>
              <th>권리자</th>
              {showPatent === "patent" && <th>관리주체/비용분담률</th>}
              <th>금액</th>
              <th>
                <p className="select_text">
                  검토
                  <i
                    className="icon_arrow_down_gray"
                    onClick={() => {
                      showSelect.review = !showSelect.review;
                      setShowSelect({
                        ...showSelect,
                        nation: false,
                        annualFee: false,
                      });
                    }}
                  />
                </p>
                {showSelect.review && (
                  <ul className="annual_list">
                    <li onClick={() => onChangeReviewState(0)}>전 체</li>
                    <li onClick={() => onChangeReviewState(1)}>검토중</li>
                    <li onClick={() => onChangeReviewState(2)}>검토하기</li>
                  </ul>
                )}
              </th>
              <th>
                <p className="select_text">
                  연차료 포기/납부{" "}
                  <i
                    className="icon_arrow_down_gray"
                    onClick={() => {
                      showSelect.annualFee = !showSelect.annualFee;
                      setShowSelect({
                        ...showSelect,
                        nation: false,
                        review: false,
                      });
                    }}
                  />
                </p>
                {showSelect.annualFee && (
                  <ul className="annual_list">
                    <li onClick={() => onChangePayState("all")}>전 체</li>
                    {payStateArr?.map((el) => {
                      return (
                        <li onClick={() => onChangePayState(el.payment_state)}>
                          {
                            payStateList.find(
                              (item) => item.key === el.payment_state
                            ).value
                          }
                        </li>
                      );
                    })}
                  </ul>
                )}
              </th>
              <th
                style={{
                  ...tHeadStyle,
                }}
              >
                <p>비용청구</p>
                <i className="icon_badge_info_gray" />
              </th>
              <th>청구서 등록/수정</th>
              <th style={{ ...tHeadStyle }}>
                지시날짜
                {calenderDate !== null ? (
                  <i
                    className="icon_exit_gray"
                    onClick={(e) => {
                      e.preventDefault();
                      setCalenderDate(null);
                      setCurPage(1);
                    }}
                  />
                ) : (
                  <i
                    className="icon_calendar"
                    onClick={(e) => {
                      e.preventDefault();
                      setCalenderOpen(!calenderOpen);
                    }}
                  />
                )}
                {calenderOpen && (
                  <DatePicker
                    selected={calenderDate}
                    onChange={(e) => {
                      setCalenderOpen(!calenderOpen);
                      setCalenderDate(e);
                      setCurPage(1);
                    }}
                    inline
                  />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((el, idx) => {
              return (
                <tr>
                  {paymentToggle && (
                    <td>
                      {el.payment_state === 1 ? (
                        <input
                          type="checkbox"
                          checked={paymentList.some((item) => item === el.idx)}
                          onChange={(e) => handleCheckboxChange(e, el)}
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                  )}
                  <td>{(curPage - 1) * count + (idx + 1)}</td>
                  <td>{el.manage_number}</td>
                  <td>{el.nation_code}</td>
                  <td>{el.apply_number}</td>
                  <td>{window.$Global.convertDate(el.apply_at)}</td>
                  <td>{el.register_number || "미등록"}</td>
                  <td>
                    {window.$Global.convertDate(el.register_at) || "미등록"}
                  </td>
                  <td>
                    {window.$Global.convertDate(el.annual_payment_deadline)}
                  </td>
                  <td>{el.payment_year}</td>
                  <td>{el.claim}</td>
                  <td>
                    {el.joint_owner_list?.map((item) => {
                      return item;
                    })}
                  </td>
                  {showPatent === "patent" && (
                    <td>
                      <button
                        className="button_review"
                        onClick={() => {
                          setIsShowPerCost(true);
                          setAnnualFeeData({
                            ...el,
                          });
                        }}
                      >
                        확인하기
                      </button>
                    </td>
                  )}
                  <td>
                    {el.nation === 0
                      ? el.cost
                        ? window.$Global.numberWithCommas(el.cost) + "원"
                        : ""
                      : el.cost_internal
                      ? window.$Global.numberWithCommas(el.cost_internal) +
                        el.money_symbol
                      : ""}
                  </td>
                  <td>
                    <button
                      className="button_review"
                      onClick={() => {
                        setShowPopup({
                          first: true,
                          second: false,
                        });
                        setAnnualFeeData({
                          manage_number: el.manage_number,
                          register_number: el.register_number,
                          annual_pay_idx: el.idx,
                          company_idx: company_idx,
                          patent_idx: el.patent_idx,
                          trademark_idx: el.trademark_idx,
                          design_idx: el.design_idx,
                        });
                      }}
                    >
                      <AnnualFeeReviewState type={el.review_cnt} />
                    </button>
                  </td>
                  <td
                    className={
                      el.payment_state === 1 ? "flex_td billing_button" : ""
                    }
                  >
                    {window.$Global.convertPayStateTypeToStr(el.payment_state)}
                    {el.payment_state === 1 && (
                      <button onClick={() => handleComplete(el.idx)}>
                        납부완료
                      </button>
                    )}
                  </td>
                  <td>
                    <AnnualPayReceipt item={el} />
                  </td>
                  <td className="billing_button">
                    {el.payment_state >= 1 && (
                      <button
                        onClick={() => {
                          console.log(companyInfo);
                          setShowPopup({
                            ...showPopup,
                            billing: true,
                          });
                          setAnnualFeeData({
                            ...annualFeeData,
                            annual_pay_idx: el.idx,
                            register_number: el.register_number,
                            patent_idx: company_idx,
                            trademark_idx: company_idx,
                            design_idx: company_idx,
                            billing_idx: el.billing_idx,
                            nation: el.nation_code,
                            cost: el.cost,
                            payment_year: el.payment_year,
                          });
                        }}
                      >
                        {el.billing_idx === null
                          ? "청구서 등록"
                          : "청구서 수정"}
                      </button>
                    )}
                  </td>
                  <td>{el.giveup_date || el.application_date}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      {showPopup.first && (
        <AnnualFeeReview
          annualFeeData={annualFeeData}
          setShowPopup={setShowPopup}
          showPatent={showPatent}
          isAdmin={true}
        />
      )}
      {showPopup.second && (
        <AnnualFeeReviewSecond
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
          isAdmin={true}
        />
      )}
      {showPopup.receipt && (
        <AnnualFeeReceipt
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
          isAdmin={true}
        />
      )}
      {showPopup.billing && (
        <AnnualFeeBilling
          setShowPopup={setShowPopup}
          companyInfo={companyInfo}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
          getPatentList={getPatentList}
          getTradeMarkList={getTradeMarkList}
          getDesignList={getDesignList}
          isAdmin={true}
        />
      )}
      {isShowPerCost && (
        <AnnualFeePerCost
          annualFeeData={annualFeeData}
          setShowPopup={setIsShowPerCost}
          isAdmin={true}
          refresh={handleSearch}
        />
      )}
    </div>
  );
};

export default AdminAnnualFeeList;
