import React, { useContext } from "react";
import PatentContext from "../../../../store/Patent";
import _ from "lodash";

function InventorRow({
  index,
  editing,
  inventor,
  inventorSelect,
  inventorSelectTag,
  onClickEvent,
}) {
  const patentInfo = useContext(PatentContext);
  let copy = _.cloneDeep(patentInfo);

  return (
    <div className="row">
      <h2 className="title">
        발명자 {index + 1}
        {editing &&
          (index == 0 ? (
            <button className="icon_plus" onClick={onClickEvent} />
          ) : (
            <button
              className="icon_badge_del"
              onClick={() => onClickEvent(index)}
            />
          ))}
        {index == 0 && (
          <i
            className="icon_info"
            data-content={window.$Global.getInfoMessage("발명자1")}
          />
        )}
      </h2>
      {editing ? (
        <select
          defaultValue={inventor.inventor_idx || 0}
          onChange={(e) => {
            let data = inventorSelect.filter(
              (item) => item.inventor_idx == e.target.value
            )[0];
            copy.inventor[index].inventor_idx = e.target.value;
            copy.inventor[index].name =
              e.target.options[e.target.selectedIndex].text;
            copy.inventor[index].birth_date = data.birth_date;
            copy.inventor[index].email = data.email;
            patentInfo.setPatent(copy);
          }}
        >
          <option value={0} disabled hidden>
            선택
          </option>
          {inventorSelectTag}
        </select>
      ) : (
        <p
          className={`${
            Boolean(inventor.name || inventor.inventor_name) ? "" : "color_blue"
          }`}
        >
          {inventor.name ||
            inventor.inventor_name ||
            "수정을 눌러 입력해 주세요."}
        </p>
      )}
    </div>
  );
}
export default InventorRow;
