import React from "react";

function SelectRow({
  title,
  titleClass,
  selectTag,
  conditions,
  content,
  contentIdx,
  onChange,
}) {
  return (
    <div className="row">
      <h2 className={`title ${titleClass || ""}`}>
        {title}
        <i
          className="icon_info"
          data-content={window.$Global.getInfoMessage(title)}
        />
      </h2>
      {conditions ? (
        selectTag.length ? (
          <select defaultValue={content ? contentIdx : 0} onChange={onChange}>
            <option>선택하세요.</option>
            {selectTag}
          </select>
        ) : (
          <p>등록 후 선택 가능</p>
        )
      ) : (
        <p className={`${Boolean(content) ? "" : "color_black"}`}>
          {content ? content : "N/A"}
        </p>
      )}
    </div>
  );
}

export default SelectRow;
