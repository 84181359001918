import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "./css/AdminPosting.scss";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import AdminAPI from "../../API/admin/index";
import CommonAPI from "../../API/common";
import TutorialAPI from "../../API/tutorial";
import DetailPopupUpload from "../common/DetailPopupUpload";

function AdminPosting({ history }) {
  const { view_type, type, idx } = useParams();

  const isNew = idx === "new";
  const isPostType = view_type === "post";
  const isNews = type === "news";
  const isYoutube = type === "youtube";
  const isNotify = type === "notify";
  const isQna = type === "qna";
  const isEdu = type === "edu";
  const isVideo = type === "video";
  const isDoc = type === "document";
  const isLove = type === "love";

  const fileRef = useRef(null);
  const thumFileRef = useRef(null);
  const editorRef = useRef(null);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [content, setContent] = useState("");
  const [upPopupShow, setUpPopupShow] = useState(false);
  const [historyFiles, setHistoryFiles] = useState([]);
  const [uploadFileViewList, setUploadFileViewList] = useState([]);

  useEffect(async () => {
    if (isNew) return;

    let result = {};

    if (isNews) {
      result = await AdminAPI.getNewsInfo(idx).then((res) => res.data);
    } else if (isYoutube) {
      result = await AdminAPI.getYoutubeInfo(idx).then((res) => res.data);
    } else if (isNotify || isLove) {
      result = await CommonAPI.getNoticeInfo(idx).then((res) => res.data);
    } else if (isQna) {
      result = await CommonAPI.getQnaInfo(idx).then((res) => res.data);
    } else if (isEdu) {
      result = await CommonAPI.getEduInfo(idx).then((res) => res.data);
    } else if (isVideo || isDoc) {
      result = await TutorialAPI.getInfo(idx).then((res) => res.data);
    }
    setHistoryFiles(result.files || result.file || []);
    setTitle(result.title || result.question);
    setLink(result.url);
    setContent(result.content || result.answer);
  }, []);

  const onClickPost = async () => {
    let formData = new FormData();

    if (isNews || isYoutube) {
      const files = [...fileRef.current.files];
      files.forEach((item) => {
        formData.append("file", item);
      });
      formData.append("url", link);
      formData.append("title", title);
      formData.append("content", content);
    } else {
      uploadFileViewList.forEach((item) => {
        formData.append("file", item.file);
      });
    }

    let parse_result = content || "";

    let src = parse_result.match(
      new RegExp('src=\\"data:image\\/([a-zA-Z]*);base64,([^\\"]*)\\"', "g")
    );
    if (src != null) {
      for (let i = 0; i < src.length; i++) {
        let data_uri = src[i].replace('src="', "");
        if (data_uri.substr(0, 5) == "https") {
          continue;
        }
        let dataUri = data_uri.replace('"', "");
        let imgUrl = window.$Global.getCDN(
          await saveFile(window.$Global.dataURItoBlob(dataUri))
        );
        parse_result = parse_result.replace(dataUri, imgUrl);
      }
    }

    let payload = {
      title: title,
      question: title,
      content: parse_result,
      answer: parse_result,
    };

    if (isVideo || isDoc) {
      thumFileRef.current.files.forEach((item) => {
        formData.append("thumbnail", item);
      });
      formData.append("title", title);
      formData.append("type", isVideo ? "0" : "1");

      let videoContent = parse_result;

      if (isVideo) {
        // videoContent = JSON.stringify(await getVideo()).replace(/\"/gi, "");
        formData.append("content", videoContent);
      }

      if (isDoc) {
        if (videoContent.length !== 2) {
          formData.append("content", videoContent);
        }
      }
    }

    if (isEdu) {
      fileRef.current.files.forEach((item) => {
        formData.append("file", item);
      });
      formData.append("title", title);
      formData.append("content", parse_result);
    }

    if (isNew) {
      if (isNews) {
        await AdminAPI.addNews(formData);
      } else if (isYoutube) {
        await AdminAPI.addYoutube(formData);
      } else if (isNotify) {
        await AdminAPI.addNotify(payload);
      } else if (isQna) {
        await AdminAPI.addQna(payload);
      } else if (isEdu) {
        await AdminAPI.addEdu(formData);
      } else if (isVideo || isDoc) {
        await AdminAPI.addTutorial(formData);
      } else if (isLove) {
        payload.category = 1;
        await AdminAPI.addNotify(payload);
      }
      alert("등록되었습니다.");
      history.go(-1);
    } else {
      if (isNews) {
        await AdminAPI.updateNews(idx, formData);
      } else if (isYoutube) {
        await AdminAPI.updateYoutube(idx, formData);
      }

      if (isNotify || isLove) {
        await AdminAPI.updateNotify(idx, payload);

        if (uploadFileViewList.length) {
          await AdminAPI.uploadNotifyFile(idx, formData);
        }
      } else if (isQna) {
        await AdminAPI.updateQna(idx, payload);
        if (uploadFileViewList.length) {
          await AdminAPI.uploadQnaFile(idx, formData);
        }
      } else if (isEdu) {
        await AdminAPI.updateEdu(idx, formData);
        if (uploadFileViewList.length) {
          await AdminAPI.uploadEduFile(idx, formData);
        }
      } else if (isVideo || isDoc) {
        await AdminAPI.updateTutorial(idx, formData);
      }
      alert("수정되었습니다.");
      history.go(-2);
    }
  };

  const getVideo = async () => {
    let tag = "";
    for (let i = 0; i < fileRef.current.files.length; i++) {
      let item = fileRef.current.files[i];
      let url = window.$Global.getCDN(await saveFile(item));
      tag += `<video controls><source src=${url} type=video/mp4></video>`;
    }
    return tag;
  };

  const saveFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return await AdminAPI.uploadImage(formData).then(
      (res) => res.data[0].file_key
    );
  };

  const onClickPopup = async () => {
    let result = [];
    if (!isNew && uploadFileViewList.length == 0) {
      if (isNotify || isLove) {
        result = await AdminAPI.getNotifyFileList(idx).then((res) => res.data);
      } else if (isQna) {
        result = await AdminAPI.getQnaFileList(idx).then((res) => res.data);
      } else if (isVideo || isDoc) {
        result = await AdminAPI.getTutorialFileList(idx).then(
          (res) => res.data
        );
      }
      setUploadFileViewList(result);
    } else {
      setUploadFileViewList(historyFiles);
    }
    setUpPopupShow(true);
  };

  const onClickUpload = () => {
    setHistoryFiles(uploadFileViewList);
    setUpPopupShow(false);
  };

  const onClickDelete = async (file_idx) => {
    let del_idx = file_idx;

    if (isNaN(file_idx)) {
      del_idx = uploadFileViewList.findIndex((item) => item.idx == file_idx);

      if (isNotify || isLove) {
        await AdminAPI.deleteNotifyFile(idx, file_idx);
      } else if (isQna) {
        await AdminAPI.deleteQnaFile(idx, file_idx);
      } else if (isVideo || isDoc) {
        await AdminAPI.deleteTutorialFile(idx, file_idx);
      }
    }
    let copyArr = uploadFileViewList.slice(0);
    copyArr.splice(del_idx, 1);
    setUploadFileViewList(copyArr);
  };

  const onClickDeletePost = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
      if (isNews) {
        await AdminAPI.deleteNews(idx);
      } else if (isYoutube) {
        await AdminAPI.deleteYoutube(idx);
      } else if (isNotify || isLove) {
        await AdminAPI.deleteNotify(idx);
      } else if (isQna) {
        await AdminAPI.deleteQna(idx);
      } else if (isEdu) {
        await AdminAPI.deleteEdu(idx);
      } else if (isVideo || isDoc) {
        await AdminAPI.deletetutorial(idx);
      }
      alert("삭제되었습니다.");
      redirectList();
    }
  };

  const redirectList = () => {
    history.push(`/admin/list/${type}`);
  };

  let files = historyFiles.map((item, index) => {
    return <li key={index}>{item.file_name + "." + item.file_type}</li>;
  });

  return (
    <div id="AdminPosting">
      <div className="header">
        <h2>게시물 작성</h2>
        <div className="btns">
          {isPostType ? (
            <>
              <button
                className="btn_cancle"
                onClick={() =>
                  history.push(`/admin/posting/view/${type}/${idx}`)
                }
              >
                취소
              </button>
              <button className="btn_write" onClick={onClickPost}>
                완료
              </button>
            </>
          ) : (
            <>
              <button className="btn_cancle" onClick={redirectList}>
                목록
              </button>
              <button className="btn_delete" onClick={onClickDeletePost}>
                삭제
              </button>
              <button
                className="btn_write"
                onClick={() =>
                  history.push(`/admin/posting/post/${type}/${idx || "new"}`)
                }
              >
                수정
              </button>
            </>
          )}
        </div>
      </div>
      <div className="title">
        <h2>제목</h2>
        {isPostType ? (
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        ) : (
          <p>{title}</p>
        )}
      </div>
      {(isNews || isYoutube) && (
        <div className="title">
          <h2>링크</h2>
          {isPostType ? (
            <input
              type="text"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          ) : (
            <p>{link}</p>
          )}
        </div>
      )}
      {isPostType && (isVideo || isDoc) && (
        <div className="content">
          <h2>썸네일</h2>
          <input type="file" ref={thumFileRef} />
        </div>
      )}
      <div className="content">
        <h2>{isYoutube ? "채널명" : "내용"}</h2>
        <div className="btns">
          {isPostType && !isVideo && (
            <>
              <h2>{isNews || isYoutube ? "썸네일" : "첨부파일"}</h2>
              {isNews || isYoutube || isVideo || isEdu ? (
                <input type="file" ref={fileRef} multiple />
              ) : (
                <div>
                  <button
                    className="icon_upload"
                    onClick={() => onClickPopup()}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="input">
        {isPostType && (isNews || isYoutube) && (
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        )}
        {isPostType && !isNews && !isYoutube && (
          <SunEditor
            lang="ko"
            ref={editorRef}
            defaultValue={content}
            onChange={(e) => setContent(e)}
            setOptions={{
              height: 800,
              buttonList: [
                ["undo", "redo", "font", "fontSize", "formatBlock"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "removeFormat",
                ],
                [
                  "fontColor",
                  "hiliteColor",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "table",
                ],
                [
                  "link",
                  "image",
                  "video",
                  "fullScreen",
                  "codeView",
                  "preview",
                  "print",
                ],
                // ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
              ],
            }}
          />
        )}
        {!isPostType && (
          <p
            className="sun-editor-editable"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
      {!isPostType && !isNews && !isYoutube && !isEdu && (
        <div className="file">
          <h2>첨부 파일</h2>
          <ul>{files}</ul>
        </div>
      )}
      <DetailPopupUpload
        show={upPopupShow}
        setShow={setUpPopupShow}
        uploadFileViewList={uploadFileViewList}
        setFileList={setUploadFileViewList}
        onClickUpload={onClickUpload}
        prop_onClickDelete={onClickDelete}
      />
    </div>
  );
}

export default AdminPosting;
