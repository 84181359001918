import { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-chart-treemap";

Chart.plugins.unregister(ChartDataLabels); // datalabels 충돌나서 해제

const Treemap = ({
  chartData,
  label,
  colors,
  isComma,
  unit,
  onClickHandler,
}) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colorFromValue = (value) => {
    const index = value;
    if (index !== -1 && index < colors.length) {
      return colors[index];
    } else {
      return generateRandomColor();
    }
  };

  const formatNumber = (num) => {
    return isComma ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : num;
  };

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      // Custom plugin for drawing text
      const drawTextPlugin = {
        afterDatasetsDraw: function (chart) {
          const ctx = chart.ctx;
          const dataset = chart.data.datasets[0];

          // Access the treemap data points
          if (dataset && dataset.data && dataset.tree) {
            dataset.data.forEach(function (rect, index) {
              const data = rect._data.children[0];
              const rectWidth = rect.w;
              const rectHeight = rect.h;

              ctx.save();
              ctx.fillStyle = "white";
              ctx.textAlign = "left";
              ctx.textBaseline = "bottom";
              const label = `${data.title} ${window.$Global.commaify(
                data.value
              )}건`;
              const centerX = rect.x + 10;
              const centerY = rect.y + rect.h - 10;

              if (rectWidth > 100 && rectHeight > 30) {
                ctx.fillText(label, centerX, centerY);
              }

              ctx.restore();
            });
          }
        },
      };

      // Registering the custom plugin
      Chart.plugins.register(drawTextPlugin);

      chartInstanceRef.current = new Chart(ctx, {
        type: "treemap",
        data: {
          datasets: [
            {
              label: label,
              tree: chartData,
              key: "value",
              groups: ["title"],
              // fontColor: "#fff",
              spacing: 0,
              backgroundColor: function (ctx) {
                return colorFromValue(ctx.dataIndex);
              },
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          title: {
            display: false,
            text: label,
          },
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              title: function (item, data) {
                return data.datasets[item[0].datasetIndex].title;
              },
              label: function (item, data) {
                var dataset = data.datasets[item.datasetIndex];
                var dataItem = dataset.data[item.index];
                return (
                  dataItem.g +
                  ": " +
                  formatNumber(dataItem.v) +
                  (unit ? unit : "")
                );
              },
            },
          },
          onClick: (e, item) => {
            try {
              if (item.length > 0) {
                const datasetIndex = item[0]._datasetIndex;
                const dataIndex = item[0]._index;
                const clickedData =
                  chartInstanceRef.current.data.datasets[datasetIndex].data[
                    dataIndex
                  ];
                if (onClickHandler) {
                  onClickHandler(clickedData);
                }
              }
            } catch (error) {
              console.error("Error handling click event: ", error);
            }
          },
        },
      });
    }
  }, [chartData, colors, label, isComma]);

  return (
    <div className="treemap">
      <canvas ref={chartRef} height={"250px"}></canvas>
    </div>
  );
};

export default Treemap;
