import InputRow from "../row/InputRow";
import DatePickerAndFileRow from "../row/DatePickerAndFileRow";
import ToggleButtonRow from "../row/ToggleButtonRow";
import DatePickerRow from "../row/DatePickerRow";
import React, { useEffect, useContext, useState } from "react";
import TradeMarkFileTypeContext from "../../../store/TradeMarkFileType";

const FormApplyKR = ({
  isNewTrademark,
  tradeMark,
  setTradeMark,
  deleteCategory,
  onClickDownPopShow,
  onClickUpPopShow,
  setCategoryNumber,
  onClickSave,
  handleEditing,
  buttonName,
}) => {
  const fileType = useContext(TradeMarkFileTypeContext);

  const [foldingKrApply, setFoldingKrApply] = useState(false);

  return (
    <div
    id="applyField"
      className={`field ${
        !isNewTrademark && tradeMark.applyEditing ? "edit-mode-field" : ""
      }`}
    >
      <div className="field_header">
        <h5 className="title" style={{ fontSize: "20px" }}>
          <i
            className={
              foldingKrApply ? "icon_arrow_up_gray" : "icon_arrow_down_gray"
            }
            onClick={() => setFoldingKrApply(!foldingKrApply)}
          />
          출원
        </h5>
        {!foldingKrApply && (
          <>
            {!isNewTrademark && tradeMark.applyEditing ? (
              <>
                <div className="btn-group">
                  <button className="btn_save" onClick={onClickSave}>
                    저장
                  </button>
                  <button
                    className="btn_cancel"
                    onClick={() => {
                      handleEditing("applyCancelEditing");
                    }}
                  >
                    취소
                  </button>
                </div>
              </>
            ) : (
              !isNewTrademark &&
              !tradeMark.applyEditing &&
              buttonName === "항목 삭제" && (
                <button
                  className="btn_edit"
                  onClick={() => handleEditing("applyEditing")}
                >
                  수정
                </button>
              )
            )}
            {!isNewTrademark &&
              !tradeMark.applyEditing &&
              buttonName === "항목 저장" && (
                <button
                  className="btn_delete"
                  onClick={() => deleteCategory("apply")}
                >
                  삭제
                </button>
              )}
          </>
        )}
      </div>
      {!foldingKrApply && (
        <>
          <div className="rows">
            <InputRow
              title="출원번호"
              conditions={tradeMark.applyEditing}
              content={tradeMark.apply_number}
              onChange={(e) =>
                setTradeMark({
                  ...tradeMark,
                  apply_number: e.target.value,
                })
              }
            />
            <DatePickerAndFileRow
              title="출원일"
              haveFile={tradeMark.apply_file_cnt}
              conditions={tradeMark.applyEditing}
              data={tradeMark.apply_at}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  apply_at: new Date(e).getTime(),
                })
              }
              onClickDownPopShow={() => {
                onClickDownPopShow(fileType.apply);
              }}
              onClickUpPopShow={() => {
                onClickUpPopShow(fileType.apply);
              }}
            />
            <DatePickerAndFileRow
              title="출원소급일"
              haveFile={tradeMark.apply_retroactive_file_cnt}
              conditions={tradeMark.applyEditing}
              data={tradeMark.apply_retroactive_at}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  apply_retroactive_at: new Date(e).getTime(),
                })
              }
              onClickDownPopShow={() => {
                onClickDownPopShow(fileType.apply_file, null, 9, null);
              }}
              onClickUpPopShow={() => {
                onClickUpPopShow(fileType.apply_file, null, 9);
                setCategoryNumber(9);
              }}
            />
          </div>
          <div className="rows">
            <div className="row" style={{ border: "none" }}></div>
            <DatePickerAndFileRow
              title="우선심사청구일"
              haveFile={tradeMark.apply_priority_screen_file_cnt}
              conditions={tradeMark.applyEditing}
              data={tradeMark.priority_exam_request_at}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  priority_exam_request_at: new Date(e).getTime(),
                })
              }
              onClickDownPopShow={() => {
                onClickDownPopShow(fileType.apply_file, null, 10);
              }}
              onClickUpPopShow={() => {
                onClickUpPopShow(fileType.apply_file, null, 10);
                setCategoryNumber(10);
              }}
            />
            <DatePickerAndFileRow
              title="우선심사결정일"
              haveFile={tradeMark.apply_priority_screen_decision_file_cnt}
              conditions={tradeMark.applyEditing}
              data={tradeMark.priority_screen_decision_at}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  priority_screen_decision_at: new Date(e).getTime(),
                })
              }
              onClickDownPopShow={() => {
                onClickDownPopShow(fileType.apply_file, null, 11);
              }}
              onClickUpPopShow={() => {
                onClickUpPopShow(fileType.apply_file);
                setCategoryNumber(11);
              }}
            />
          </div>
          <div className="rows">
            <InputRow
              title="출원공고번호"
              conditions={tradeMark.applyEditing}
              content={tradeMark.open_number}
              onChange={(e) =>
                setTradeMark({
                  ...tradeMark,
                  open_number: e.target.value,
                })
              }
            />
            <DatePickerAndFileRow
              title="출원공고일"
              haveFile={tradeMark.apply_decision_file_cnt}
              conditions={tradeMark.applyEditing}
              data={tradeMark.open_at}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  open_at: new Date(e).getTime(),
                })
              }
              onClickDownPopShow={() => {
                onClickDownPopShow(fileType.apply_file, null, 12);
              }}
              onClickUpPopShow={() => {
                onClickUpPopShow(fileType.apply_file);
                setCategoryNumber(12);
              }}
            />
            <DatePickerAndFileRow
              title="이의신청기간"
              haveFile={tradeMark.of2_cnt}
              conditions={tradeMark.applyEditing}
              data={tradeMark.objection_deadline}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  objection_deadline: new Date(e).getTime(),
                })
              }
              onClickDownPopShow={() => {
                onClickDownPopShow(fileType.apply_file, null, 39);
              }}
              onClickUpPopShow={() => {
                onClickUpPopShow(fileType.apply_file);
                setCategoryNumber(39);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FormApplyKR;
