import { exportTypedArrayMethod } from "core-js/internals/array-buffer-view-core";

export const nationType = [
  "KR",
  "PCT",
  "US",
  "EP",
  "CN",
  "JP",
  "TW", // 대만
  "RU", // 러시아
  "NZ", // 뉴질랜드
  "IN", // 인도
  "VN", // 베트남
  "AU", // 호주
  "CA", // 캐나다
  "BR", // 브라질
  "SP", // 스페인
  "MO", // 마카오
  "SG", // 싱가폴
  "MX", // 멕시코
  "IL", // 이스라엘
  "PH", // 필리핀
  "HK", // 홍콩
  "EA", // EA
  "ZA", // 남아프리카 공화국
  "ES", // 스페인
  "DE", // 독일
];

export const nationStrType = [
  "한국",
  "PCT",
  "미국",
  "유럽",
  "중국",
  "일본",
  "대만",
  "러시아",
  "뉴질랜드",
  "인도",
  "베트남",
  "호주",
  "캐나다",
  "브라질",
  "스페인",
  "마카오",
  "싱가폴",
  "멕시코",
  "이스라엘",
  "필리핀",
  "홍콩",
  "EA",
  "남아프리카 공화국",
  "스페인",
  "독일",
];

export const tradeMarkCountryKr = [
  { EUR: "유럽" },
  { AFR: "아프리카" },
  { ASI: "아시아" },
  { AUS: "오세아니아" },
  { AMC: "아메리카" },
  { MAD: "마드리드" },
];

export const tradeMarkNationKr = [
  "한국",
  "마드리드",
  "미국",
  "유럽",
  "중국",
  "일본",
  "대만",
  "러시아",
  "뉴질랜드",
  "인도",
  "베트남",
  "호주",
  "캐나다",
  "브라질",
  "스페인",
  "마카오",
  "싱가폴",
  "멕시코",
  "이스라엘",
  "필리핀",
  "홍콩",
  "EA",
  "남아프리카 공화국",
  "인도네시아",
  "태국",
  "말레이시아",
  "아랍에미리트",
  "영국",
  "사우디 아라비아",
  "캄보디아",
  "미얀마",
  "쿠웨이트",
  "라오스",
  "카타르",
  "바레인",
  "요르단",
  "이라크",
  "오만",
  "리비아",
  "키르키스스탄",
  "우즈베키스탄",
  "우크라이나",
  "예멘",
  "이집트",
  "팔레스타인 가자지구",
  "팔레스타인 서안지구",
  "이란",
  "파나마",
  "부탄",
  "몽골",
  "몰디브",
  "브루나이",
  "트리니다드 토바고",
  "방글라데시",
  "네팔",
  "파키스탄",
  "스리랑카",
  "사모아",
  "동티모르",
  "모리셔스",
  "수리남",
  "노르웨이",
  "스위스",
  "리유니언 섬 (레위니옹)",
  "미크로네시아 연방",
  "아프가니스탄",
  "가나",
  "에티오피아",
  "볼리비아",
  "코스타리카",
  "케냐",
  "지부티",
  "피지",
  "튀니지",
  "자메이카",
  "팔라우",
  "가이아나",
  "칠레",
  "에콰도르",
  "남수단",
  "키리바시",
  "마다가스카르",
  "콜롬비아",
  "카자흐스탄",
  "프랑스",
  "아르헨티나",
  "페루",
  "파라과이",
  "몰타",
  "과테말라",
  "독일",
  "튀르키예",
];

export const tradeMarkNationType = {
  KR: 0,
  MADRID: 1,
  US: 2,
  EU: 3,
  CN: 4,
  JP: 5,
  TW: 6, // 대만
  RU: 7, // 러시아
  NZ: 8, // 뉴질랜드
  IN: 9, // 인도
  VN: 10, // 베트남
  AU: 11, // 호주
  CA: 12, // 캐나다
  BR: 13, // 브라질
  SP: 14, // 스페인
  MO: 15, // 마카오
  SG: 16, // 싱가폴
  MX: 17, // 멕시코
  IL: 18, // 이스라엘
  PH: 19, // 필리핀
  HK: 20, // 홍콩
  EA: 21, // EA
  ZA: 22, // 남아프리카 공화국
  ID: 23, // 인도네시아
  TH: 24, // 태국
  MY: 25, // 말레이시아
  AE: 26, // 아랍에미리트
  GB: 27, // 영국
  SA: 28, // 사우디 아라비아
  KH: 29, // 캄보디아
  MM: 30, // 미얀마
  KW: 31, // 쿠웨이트
  LA: 32, // 라오스
  QA: 33, // 카타르
  BH: 34, // 바레인
  JO: 35, // 요르단
  IQ: 36, // 이라크
  OM: 37, // 오만
  LY: 38, // 리비아
  KG: 39, // 키르키스스탄
  UZ: 40, // 우즈베키스탄
  UA: 41, // 우크라이나
  YE: 42, // 예멘
  EG: 43, // 이집트
  GZ: 44, // 팔레스타인 가자지구
  WB: 45, // 팔레스타인 서안지구
  IR: 46, // 이란
  PA: 47, // 파나마
  BT: 48, // 부탄
  MN: 49, // 몽골
  MV: 50, // 몰디브
  BN: 51, // 브루나이
  TT: 52, // 트리니다드 토바고
  BD: 53, // 방글라데시
  NP: 54, // 네팔
  PK: 55, // 파키스탄
  LK: 56, // 스리랑카
  WS: 57, // 사모아
  TL: 58, // 동티모르
  MU: 59, // 모리셔스
  SR: 60, // 수리남
  NO: 61, // 노르웨이
  CH: 62, // 스위스
  RE: 63, // 리유니언 섬 (레위니옹)
  FM: 64, // 미크로네시아 연방
  AF: 65, // 아프가니스탄
  GH: 66, // 가나
  ET: 67, // 에티오피아
  BO: 68, // 볼리비아
  CR: 69, // 코스타리카
  KE: 70, // 케냐
  DJ: 71, // 지부티
  FJ: 72, // 피지
  TN: 73, // 튀니지
  JM: 74, // 자메이카
  PW: 75, // 팔라우
  GY: 76, // 가이아나
  CL: 77, // 칠레
  EC: 78, // 에콰도르
  SD: 79, // 남수단
  KI: 80, // 키리바시
  MG: 81, // 마다가스카르
  CO: 82, // 콜롬비아
  KZ: 83, // 카자흐스탄
  FR: 84, // 프랑스
  AR: 85, // 아르헨티나
  PE: 86, // 페루
  PY: 87, // 파라과이
  MT: 88, // 몰타
  GT: 89, // 과테말라
  DE: 90, // 독일
  TR: 91, // 튀르키예
};
export const designatedCountryEp = [
  "AL", // 알바니아
  "AT", // 오스트리아
  "BE", // 벨기에
  "BG", // 불가리아
  "CH", // 스위스
  "CY", // 키프로스
  "CZ", // 체코
  "DE", // 독일
  "DK", // 덴마크
  "EE", // 에스토니아
  "ES", // 스페인
  "FI", // 핀란드
  "FR", // 프랑스
  "GB", // 영국
  "GR", // 그리스
  "HR", // 크로아티아
  "HU", // 헝가리
  "IE", // 아일랜드
  "IS", // 아이슬란드
  "IT", // 이탈리아
  "LI", // 리히텐슈타인
  "LT", // 리투아니아
  "LU", // 룩셈부르크
  "LV", // 라트비아
  "MC", // 모나코
  "MK", // 북마케도니아
  "MT", // 몰타
  "NL", // 네덜란드
  "NO", // 노르웨이
  "PL", // 폴란드
  "PT", // 포르투갈
  "RO", // 루마니아
  "RS", // 세르비아
  "SE", // 스웨덴
  "SI", // 슬로베니아
  "SK", // 슬로바키아
  "SM", // 산마리노
  "TR", // 튀르키예
  "SG", // 싱가포르
];

export const tradeMarkCountry = [
  "AE",
  "AF",
  "AG",
  "AL",
  "AM",
  "AT",
  "AU",
  "AZ",
  "BA",
  "BE",
  "BG",
  "BH",
  "BN",
  "BR",
  "BT",
  "BW",
  "BY",
  "CA",
  "CH",
  "CN",
  "CO",
  "CU",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DZ",
  "EE",
  "EG",
  "EM",
  "ES",
  "FI",
  "FR",
  "GB",
  "GE",
  "GH",
  "GM",
  "GR",
  "HR",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IR",
  "IS",
  "IT",
  "JP",
  "KE",
  "KG",
  "KH",
  "KP",
  "KR",
  "KZ",
  "LA",
  "LI",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "MA",
  "MC",
  "MD",
  "ME",
  "MG",
  "MK",
  "MN",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NL",
  "NO",
  "NZ",
  "OA",
  "OM",
  "PH",
  "PK",
  "PL",
  "PT",
  "RO",
  "RS",
  "RU",
  "RW",
  "SD",
  "SE",
  "SH",
  "SI",
  "SK",
  "SL",
  "SM",
  "ST",
  "SY",
  "SZ",
  "TH",
  "TJ",
  "TM",
  "TN",
  "TR",
  "TT",
  "UA",
  "US",
  "YZ",
  "VN",
  "WS",
  "ZM",
  "ZW",
];
