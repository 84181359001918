/* 추가적인 DB와 서버작업을 하지 않기 위해
   기존의 국내상표 거절결정 필드 > 재심사 서브필드에서
   중간사건 유무, 중간사건을
   심결취소소송(제2심/최종심) 서브필드에서 사용 */

import React, { useEffect, useContext, useState } from "react";
import TradeMarkFileTypeContext from "../../../store/TradeMarkFileType";
import DatePickerAndFileRow from "../row/DatePickerAndFileRow";
import ToggleButtonRow from "../row/ToggleButtonRow";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import SelectRow from "../../patent/detail/common/SelectRow";
import DatePickerRow from "../row/DatePickerRow";
import { krAndUsCategory, overseasCategory } from "../categoryList";
import CommonAPI from "../../../API/common";
import { Link, useParams, useHistory } from "react-router-dom";
import TradeMarkAPI from "../../../API/trademark";
function FormRejectOverseas({
  isCompany,
  isAgent,
  manage_company_idx,
  tradeMark,
  setTradeMark,
  isNewTrademark,
  onClickDownPopShow,
  onClickUpPopShow,
  setSecondIdx,
  addMiddleEventItem,
  deleteMiddleEventItem,
  //selectTagName,
  judgmentStateListTag,

  deleteCategory,
  onClickSave,
  selectTagName,
  handleEditing,
  buttonName,
}) {
  const fileType = useContext(TradeMarkFileTypeContext);
  const [foldingRejectOverseas, setfoldingRejectOverseas] = useState();

  return (
    <div
    id="rejectField"
      className={`field ${
        !isNewTrademark && tradeMark.rejectEditing ? "edit-mode-field" : ""
      }`}
    >
      <div className="field_header">
        <h5 className="title" style={{ fontSize: "20px" }}>
          <i
            className={
              foldingRejectOverseas
                ? "icon_arrow_up_gray"
                : "icon_arrow_down_gray"
            }
            onClick={() => setfoldingRejectOverseas(!foldingRejectOverseas)}
          />
          거절결정
        </h5>
        {!foldingRejectOverseas && (
          <>
            {!isNewTrademark && tradeMark.rejectEditing ? (
              <>
                <div className="btn-group">
                  <button className="btn_save" onClick={onClickSave}>
                    저장
                  </button>
                  <button
                    className="btn_cancel"
                    onClick={() => {
                      handleEditing("rejectCancelEditing");
                    }}
                  >
                    취소
                  </button>
                </div>
              </>
            ) : (
              !isNewTrademark &&
              !tradeMark.rejectEditing &&
              buttonName === "항목 삭제" && (
                <button
                  className="btn_edit"
                  onClick={() => handleEditing("rejectEditing")}
                >
                  수정
                </button>
              )
            )}
            {!isNewTrademark &&
              !tradeMark.rejectEditing &&
              buttonName === "항목 저장" && (
                <button
                  className="btn_delete"
                  onClick={() => deleteCategory("reject")}
                >
                  삭제
                </button>
              )}
          </>
        )}
      </div>
      {!foldingRejectOverseas && (
        <>
          <div className="rows">
            <DatePickerAndFileRow
              title="거절결정일"
              conditions={tradeMark.rejectEditing}
              data={tradeMark.reject_receipt_at}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  reject_receipt_at: new Date(e).getTime(),
                })
              }
              haveFile={tradeMark.reject_decision_file_cnt}
              onClickDownPopShow={() => {
                onClickDownPopShow(fileType.reject, null, 8);
                setSecondIdx(8);
              }}
              onClickUpPopShow={() => {
                onClickUpPopShow(fileType.reject);
                setSecondIdx(8);
              }}
            />
            <DatePickerAndFileRow
              title="불복기한"
              conditions={tradeMark.rejectEditing}
              data={tradeMark.reject_deadline}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  reject_deadline: new Date(e).getTime(),
                })
              }
              haveFile={tradeMark.reject_objection_file_cnt}
              onClickDownPopShow={() => {
                onClickDownPopShow(fileType.reject, null, 13);
                setSecondIdx(13);
              }}
              onClickUpPopShow={() => {
                onClickUpPopShow(
                  isNewTrademark ? fileType.reject_deadline : fileType.reject
                );
                setSecondIdx(13);
              }}
            />
            <ToggleButtonRow
              title="대응포기 여부"
              conditions={tradeMark.rejectEditing}
              data={tradeMark.reject_give_up}
              selected={tradeMark.reject_give_up}
              toggleSelected={() =>
                setTradeMark({
                  ...tradeMark,
                  reject_give_up: Number(!tradeMark.reject_give_up),
                })
              }
            />
          </div>
          <div className="refuse_field">
            <div className="rows">
              <div className="declare_row" style={{ color: "#4593f5" }}>
                <ToggleButtonWithSpan
                  text="불복심판(심판원)"
                  condition={{ on: "", off: "" }}
                  selected={tradeMark.show_reject_refusalDecision}
                  toggleSelected={() => {
                    if (tradeMark.rejectEditing) {
                      tradeMark.show_reject_refusalDecision = Number(
                        !tradeMark.show_reject_refusalDecision
                      );
                      setTradeMark({ ...tradeMark });
                    }
                  }}
                />
              </div>
            </div>
            {tradeMark.show_reject_refusalDecision ? (
              <>
                <div className="rows">
                  <DatePickerAndFileRow
                    title="청구일"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.refusal_decision_objection_judge_request_at}
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        refusal_decision_objection_judge_request_at: new Date(
                          e
                        ).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.reject_refusal_decision_objection_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 17);
                      setSecondIdx(17);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.refusal_decision_file1
                          : fileType.reject
                      );
                      setSecondIdx(17);
                    }}
                  />
                  <ToggleButtonRow
                    title="중간사건 유무"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.show_reject_refusalDecision_oa}
                    selected={tradeMark.show_reject_refusalDecision_oa}
                    toggleSelected={() =>
                      setTradeMark({
                        ...tradeMark,
                        show_reject_refusalDecision_oa: Number(
                          !tradeMark.show_reject_refusalDecision_oa
                        ),
                      })
                    }
                  />
                </div>
                {tradeMark.show_reject_refusalDecision_oa ? (
                  <div className="oa_field">
                    <div
                      className="rows"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {tradeMark.rejectEditing && (
                        <button
                          className="add_btn"
                          onClick={() => addMiddleEventItem(1)}
                        >
                          사건추가
                        </button>
                      )}
                    </div>
                    {tradeMark.refusal_decision_oa?.map((item, idx) => {
                      return (
                        <>
                          <div className="rows">
                            <div className="declare_row">
                              <p className="title_no_items">
                                중간사건 {idx + 1}
                              </p>
                              {tradeMark.rejectEditing && (
                                <button
                                  className="btn_delete"
                                  onClick={() =>
                                    deleteMiddleEventItem(item, idx, 1)
                                  }
                                >
                                  삭제
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="발행일"
                              conditions={tradeMark.rejectEditing}
                              data={item.published_at}
                              onChangeDatePicker={(e) => {
                                item.published_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_content_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.refusal_decision_content_file
                                    : fileType.refusal_decision_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 0
                                );
                                setSecondIdx(isNewTrademark ? idx : 0);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.refusal_decision_content_file
                                    : fileType.refusal_decision_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 0);
                              }}
                            />
                            <DatePickerAndFileRow
                              title="제출·대응기한"
                              conditions={tradeMark.rejectEditing}
                              data={item.submission_deadline}
                              onChangeDatePicker={(e) => {
                                item.submission_deadline = new Date(
                                  e
                                ).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_apply_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.refusal_decision_apply_file
                                    : fileType.refusal_decision_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 1
                                );
                                setSecondIdx(isNewTrademark ? idx : 1);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.refusal_decision_apply_file
                                    : fileType.refusal_decision_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 1);
                              }}
                            />
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="제출·대응일"
                              conditions={tradeMark.rejectEditing}
                              data={item.response_at}
                              onChangeDatePicker={(e) => {
                                item.response_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_opinion_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.refusal_decision_opinion_file
                                    : fileType.refusal_decision_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 2
                                );
                                setSecondIdx(isNewTrademark ? idx : 2);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.refusal_decision_opinion_file
                                    : fileType.refusal_decision_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 2);
                              }}
                            />
                            <ToggleButtonRow
                              title="대응포기 여부"
                              conditions={tradeMark.rejectEditing}
                              data={item.is_give_up}
                              selected={item.is_give_up}
                              toggleSelected={() => {
                                item.is_give_up = Number(!item.is_give_up);
                                setTradeMark({ ...tradeMark });
                              }}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : null}
                <div className="rows">
                  <DatePickerAndFileRow
                    title="최종심결일"
                    conditions={tradeMark.rejectEditing}
                    data={
                      tradeMark.refusal_decision_objection_judge_final_trial_at
                    }
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        refusal_decision_objection_judge_final_trial_at:
                          new Date(e).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.reject_refusal_decision_objection_final_trial_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 18);
                      setSecondIdx(18);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.refusal_decision_file2
                          : fileType.reject
                      );
                      setSecondIdx(18);
                    }}
                  />
                  <SelectRow
                    title="최종심결"
                    content={selectTagName.refusalName}
                    onChange={(e) =>
                      setTradeMark({
                        ...tradeMark,
                        refusal_decision_objection_judge_final_trial_state:
                          e.target.value,
                      })
                    }
                    conditions={tradeMark.rejectEditing}
                    contentIdx={
                      tradeMark.refusal_decision_objection_judge_final_trial_state
                    }
                    selectTag={judgmentStateListTag}
                  />
                  <DatePickerAndFileRow
                    title="불복기한"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.refusal_decision_objection_judge_deadline}
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        refusal_decision_objection_judge_deadline: new Date(
                          e
                        ).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.reject_refusal_decision_objection_final_trial_objection_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 19);
                      setSecondIdx(19);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.refusal_decision_file3
                          : fileType.reject
                      );
                      setSecondIdx(19);
                    }}
                  />
                </div>
                <div className="rows">
                  <ToggleButtonRow
                    title="대응포기 여부"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.is_refusal_decision_objection_judge_give_up}
                    selected={
                      tradeMark.is_refusal_decision_objection_judge_give_up
                    }
                    toggleSelected={() =>
                      setTradeMark({
                        ...tradeMark,
                        is_refusal_decision_objection_judge_give_up: Number(
                          !tradeMark.is_refusal_decision_objection_judge_give_up
                        ),
                      })
                    }
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="refuse_field">
            <div className="rows">
              <div className="declare_row" style={{ color: "#4593f5" }}>
                <ToggleButtonWithSpan
                  text="심결취소소송(제1심)"
                  condition={{ on: "", off: "" }}
                  selected={tradeMark.show_reject_trialDecision}
                  toggleSelected={() => {
                    if (tradeMark.rejectEditing) {
                      tradeMark.show_reject_trialDecision = Number(
                        !tradeMark.show_reject_trialDecision
                      );
                      setTradeMark({ ...tradeMark });
                    }
                  }}
                />
              </div>
            </div>
            {tradeMark.show_reject_trialDecision ? (
              <>
                <div className="rows">
                  <DatePickerAndFileRow
                    title="청구일"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.trial_decision_patent_request_at}
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        trial_decision_patent_request_at: new Date(e).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.trial_decision_revocation_suit_patent_court_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 20);
                      setSecondIdx(20);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.trial_decision_patent_file1
                          : fileType.reject
                      );
                      setSecondIdx(20);
                    }}
                  />
                  <ToggleButtonRow
                    title="중간사건 유무"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.show_reject_trialDecision_oa}
                    selected={tradeMark.show_reject_trialDecision_oa}
                    toggleSelected={() =>
                      setTradeMark({
                        ...tradeMark,
                        show_reject_trialDecision_oa: Number(
                          !tradeMark.show_reject_trialDecision_oa
                        ),
                      })
                    }
                  />
                </div>
                {tradeMark.show_reject_trialDecision_oa ? (
                  <div className="oa_field">
                    <div
                      className="rows"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {tradeMark.rejectEditing && (
                        <button
                          className="add_btn"
                          onClick={() => addMiddleEventItem(2)}
                        >
                          사건추가
                        </button>
                      )}
                    </div>
                    {tradeMark.trial_decision_patent_oa?.map((item, idx) => {
                      return (
                        <>
                          <div className="rows">
                            <div className="declare_row">
                              <p className="title_no_items">
                                중간사건 {idx + 1}
                              </p>
                              {tradeMark.rejectEditing && (
                                <button
                                  className="btn_delete"
                                  onClick={() =>
                                    deleteMiddleEventItem(item, idx, 2)
                                  }
                                >
                                  삭제
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="발행일"
                              conditions={tradeMark.rejectEditing}
                              data={item.published_at}
                              onChangeDatePicker={(e) => {
                                item.published_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_content_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.trial_decision_patent_content_file
                                    : fileType.trial_decision_patent_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 0
                                );
                                setSecondIdx(isNewTrademark ? idx : 0);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.trial_decision_patent_content_file
                                    : fileType.trial_decision_patent_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 0);
                              }}
                            />
                            <DatePickerAndFileRow
                              title="제출·대응기한"
                              conditions={tradeMark.rejectEditing}
                              data={item.submission_deadline}
                              onChangeDatePicker={(e) => {
                                item.submission_deadline = new Date(
                                  e
                                ).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_apply_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.trial_decision_patent_apply_file
                                    : fileType.trial_decision_patent_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 1
                                );
                                setSecondIdx(isNewTrademark ? idx : 1);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.trial_decision_patent_apply_file
                                    : fileType.trial_decision_patent_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 1);
                              }}
                            />
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="제출·대응일"
                              conditions={tradeMark.rejectEditing}
                              data={item.response_at}
                              onChangeDatePicker={(e) => {
                                item.response_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_opinion_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.trial_decision_patent_opinion_file
                                    : fileType.trial_decision_patent_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 2
                                );
                                setSecondIdx(isNewTrademark ? idx : 2);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.trial_decision_patent_opinion_file
                                    : fileType.trial_decision_patent_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 2);
                              }}
                            />
                            <ToggleButtonRow
                              title="대응포기 여부"
                              conditions={tradeMark.rejectEditing}
                              data={item.is_give_up}
                              selected={item.is_give_up}
                              toggleSelected={() => {
                                item.is_give_up = Number(!item.is_give_up);
                                setTradeMark({ ...tradeMark });
                              }}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : null}
                <div className="rows">
                  <DatePickerAndFileRow
                    title="판결선고일"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.trial_decision_patent_judgement_at}
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        trial_decision_patent_judgement_at: new Date(
                          e
                        ).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.trial_decision_revocation_suit_patent_court_judgement_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 21);
                      setSecondIdx(21);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.trial_decision_patent_file2
                          : fileType.reject
                      );
                      setSecondIdx(21);
                    }}
                  />
                  <SelectRow
                    title="판결"
                    content={selectTagName.trialPatentName}
                    onChange={(e) =>
                      setTradeMark({
                        ...tradeMark,
                        trial_decision_patent_judgement_state: e.target.value,
                      })
                    }
                    conditions={tradeMark.rejectEditing}
                    contentIdx={tradeMark.trial_decision_patent_judgement_state}
                    selectTag={judgmentStateListTag}
                  />
                  <DatePickerAndFileRow
                    title="불복기한"
                    conditions={tradeMark.rejectEditing}
                    data={
                      tradeMark.trial_decision_patent_judgement_objection_deadline
                    }
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        trial_decision_patent_judgement_objection_deadline:
                          new Date(e).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.trial_decision_revocation_suit_patent_court_judgement_objection_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 22);
                      setSecondIdx(22);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.trial_decision_patent_file3
                          : fileType.reject
                      );
                      setSecondIdx(22);
                    }}
                  />
                </div>
                <div className="rows">
                  <ToggleButtonRow
                    title="대응포기 여부"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.is_trial_decision_patent_judgement_give_up}
                    selected={
                      tradeMark.is_trial_decision_patent_judgement_give_up
                    }
                    toggleSelected={() =>
                      setTradeMark({
                        ...tradeMark,
                        is_trial_decision_patent_judgement_give_up: Number(
                          !tradeMark.is_trial_decision_patent_judgement_give_up
                        ),
                      })
                    }
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="refuse_field">
            <div className="rows">
              <div className="declare_row" style={{ color: "#4593f5" }}>
                <ToggleButtonWithSpan
                  text="심결취소소송(제2심/최종심)"
                  condition={{ on: "", off: "" }}
                  selected={tradeMark.show_reject_trialDecision_supreme}
                  toggleSelected={() => {
                    if (tradeMark.rejectEditing) {
                      tradeMark.show_reject_trialDecision_supreme = Number(
                        !tradeMark.show_reject_trialDecision_supreme
                      );
                      setTradeMark({ ...tradeMark });
                    }
                  }}
                />
              </div>
            </div>
            {tradeMark.show_reject_trialDecision_supreme ? (
              <>
                <div className="rows">
                  <DatePickerAndFileRow
                    title="청구일"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.trial_decision_supreme_request_at}
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        trial_decision_supreme_request_at: new Date(
                          e
                        ).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.trial_decision_revocation_suit_supreme_court_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 23);
                      setSecondIdx(23);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.trial_decision_supreme_file1
                          : fileType.reject
                      );
                      setSecondIdx(23);
                    }}
                  />
                  <ToggleButtonRow
                    title="중간사건 유무"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.show_reject_reExamination_oa}
                    selected={tradeMark.show_reject_reExamination_oa}
                    toggleSelected={() =>
                      setTradeMark({
                        ...tradeMark,
                        show_reject_reExamination_oa: Number(
                          !tradeMark.show_reject_reExamination_oa
                        ),
                      })
                    }
                  />
                </div>
                {tradeMark.show_reject_reExamination_oa ? (
                  <div className="oa_field">
                    <div
                      className="rows"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {tradeMark.rejectEditing && (
                        <button
                          className="add_btn"
                          onClick={() => addMiddleEventItem(0)}
                        >
                          사건추가
                        </button>
                      )}
                    </div>
                    {tradeMark.reject_exam_oa?.map((item, idx) => {
                      return (
                        <>
                          <div className="rows">
                            <div className="declare_row">
                              <p className="title_no_items">
                                중간사건 {idx + 1}
                              </p>
                              {tradeMark.rejectEditing && (
                                <button
                                  className="btn_delete"
                                  onClick={() =>
                                    deleteMiddleEventItem(item, idx, 0)
                                  }
                                >
                                  삭제
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="발행일"
                              conditions={tradeMark.rejectEditing}
                              data={item.published_at}
                              onChangeDatePicker={(e) => {
                                item.published_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_content_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.reject_exam_content_file
                                    : fileType.reject_exam_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 0
                                );
                                setSecondIdx(isNewTrademark ? idx : 0);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.reject_exam_content_file
                                    : fileType.reject_exam_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 0);
                              }}
                            />
                            <DatePickerAndFileRow
                              title="제출·대응기한"
                              conditions={tradeMark.rejectEditing}
                              data={item.submission_deadline}
                              onChangeDatePicker={(e) => {
                                item.submission_deadline = new Date(
                                  e
                                ).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_apply_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.reject_exam_apply_file
                                    : fileType.reject_exam_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 1
                                );
                                setSecondIdx(isNewTrademark ? idx : 1);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.reject_exam_apply_file
                                    : fileType.reject_exam_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 1);
                              }}
                            />
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="제출·대응일"
                              conditions={tradeMark.rejectEditing}
                              data={item.response_at}
                              onChangeDatePicker={(e) => {
                                item.response_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_opinion_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.reject_exam_opinion_file
                                    : fileType.reject_exam_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 2
                                );
                                setSecondIdx(isNewTrademark ? idx : 2);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.reject_exam_opinion_file
                                    : fileType.reject_exam_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 2);
                              }}
                            />
                            <ToggleButtonRow
                              title="대응포기 여부"
                              conditions={tradeMark.rejectEditing}
                              data={item.is_give_up}
                              selected={item.is_give_up}
                              toggleSelected={() => {
                                item.is_give_up = Number(!item.is_give_up);
                                setTradeMark({ ...tradeMark });
                              }}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : null}
                <div className="rows">
                  <DatePickerAndFileRow
                    title="판결선고일"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.trial_decision_supreme_judgement_at}
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        trial_decision_supreme_judgement_at: new Date(
                          e
                        ).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.trial_decision_revocation_suit_supreme_court_judgement_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 24);
                      setSecondIdx(24);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.trial_decision_supreme_file2
                          : fileType.reject
                      );
                      setSecondIdx(24);
                    }}
                  />
                  <SelectRow
                    title="판결"
                    content={selectTagName.trialSupremeName}
                    onChange={(e) =>
                      setTradeMark({
                        ...tradeMark,
                        trial_decision_supreme_judgement_state: e.target.value,
                      })
                    }
                    conditions={tradeMark.rejectEditing}
                    contentIdx={
                      tradeMark.trial_decision_supreme_judgement_state
                    }
                    selectTag={judgmentStateListTag}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="refuse_field">
            <div className="rows">
              <div className="declare_row" style={{ color: "#4593f5" }}>
                <ToggleButtonWithSpan
                  text="파기환송심(심판원)"
                  condition={{ on: "", off: "" }}
                  selected={tradeMark.show_reject_caseReversed}
                  toggleSelected={() => {
                    if (tradeMark.rejectEditing) {
                      tradeMark.show_reject_caseReversed = Number(
                        !tradeMark.show_reject_caseReversed
                      );
                      setTradeMark({ ...tradeMark });
                    }
                  }}
                />
              </div>
            </div>
            {tradeMark.show_reject_caseReversed ? (
              <>
                <div className="rows">
                  <DatePickerAndFileRow
                    title="청구일"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.case_reversed_request_at}
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        case_reversed_request_at: new Date(e).getTime(),
                      })
                    }
                    haveFile={tradeMark.case_reversed_request_at_file_cnt}
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 28);
                      setSecondIdx(28);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.case_reversed_file1
                          : fileType.reject
                      );
                      setSecondIdx(28);
                    }}
                  />
                  <ToggleButtonRow
                    title="중간사건 유무"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.show_reject_caseReversed_oa}
                    selected={tradeMark.show_reject_caseReversed_oa}
                    toggleSelected={() =>
                      setTradeMark({
                        ...tradeMark,
                        show_reject_caseReversed_oa: Number(
                          !tradeMark.show_reject_caseReversed_oa
                        ),
                      })
                    }
                  />
                </div>
                {tradeMark.show_reject_caseReversed_oa ? (
                  <div className="oa_field">
                    <div
                      className="rows"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {tradeMark.rejectEditing && (
                        <button
                          className="add_btn"
                          onClick={() => addMiddleEventItem(3)}
                        >
                          사건추가
                        </button>
                      )}
                    </div>
                    {tradeMark.case_reversed_oa?.map((item, idx) => {
                      return (
                        <>
                          <div className="rows">
                            <div className="declare_row">
                              <p className="title_no_items">
                                중간사건 {idx + 1}
                              </p>
                              {tradeMark.rejectEditing && (
                                <button
                                  className="btn_delete"
                                  onClick={() =>
                                    deleteMiddleEventItem(item, idx, 3)
                                  }
                                >
                                  삭제
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="발행일"
                              conditions={tradeMark.rejectEditing}
                              data={item.published_at}
                              onChangeDatePicker={(e) => {
                                item.published_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_content_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.case_reversed_content_file
                                    : fileType.case_reversed_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 0
                                );
                                setSecondIdx(isNewTrademark ? idx : 0);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.case_reversed_content_file
                                    : fileType.case_reversed_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 0);
                              }}
                            />
                            <DatePickerAndFileRow
                              title="제출·대응기한"
                              conditions={tradeMark.rejectEditing}
                              data={item.submission_deadline}
                              onChangeDatePicker={(e) => {
                                item.submission_deadline = new Date(
                                  e
                                ).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_apply_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.case_reversed_apply_file
                                    : fileType.case_reversed_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 1
                                );
                                setSecondIdx(isNewTrademark ? idx : 1);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.case_reversed_apply_file
                                    : fileType.case_reversed_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 1);
                              }}
                            />
                          </div>
                          <div className="rows">
                            <DatePickerAndFileRow
                              title="제출·대응일"
                              conditions={tradeMark.rejectEditing}
                              data={item.response_at}
                              onChangeDatePicker={(e) => {
                                item.response_at = new Date(e).getTime();
                                setTradeMark({ ...tradeMark });
                              }}
                              haveFile={item.middle_event_opinion_file_cnt}
                              onClickDownPopShow={() => {
                                onClickDownPopShow(
                                  isNewTrademark
                                    ? fileType.case_reversed_opinion_file
                                    : fileType.case_reversed_oa,
                                  item.idx,
                                  isNewTrademark ? idx : 2
                                );
                                setSecondIdx(isNewTrademark ? idx : 2);
                              }}
                              onClickUpPopShow={() => {
                                onClickUpPopShow(
                                  isNewTrademark
                                    ? fileType.case_reversed_opinion_file
                                    : fileType.case_reversed_oa,
                                  item.idx,
                                  idx
                                );
                                setSecondIdx(isNewTrademark ? idx : 2);
                              }}
                            />
                            <ToggleButtonRow
                              title="대응포기 여부"
                              conditions={tradeMark.rejectEditing}
                              data={item.is_give_up}
                              selected={item.is_give_up}
                              toggleSelected={() => {
                                item.is_give_up = Number(!item.is_give_up);
                                setTradeMark({ ...tradeMark });
                              }}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : null}
                <div className="rows">
                  <DatePickerAndFileRow
                    title="최종심결일"
                    conditions={tradeMark.rejectEditing}
                    data={tradeMark.re_examination_request_at}
                    onChangeDatePicker={(e) =>
                      tradeMark.setTradeMark({
                        ...tradeMark,
                        re_examination_request_at: new Date(e).getTime(),
                      })
                    }
                    haveFile={
                      tradeMark.case_reversed_and_remanded_final_trial_file_cnt
                    }
                    onClickDownPopShow={() => {
                      onClickDownPopShow(fileType.reject, null, 25);
                      setSecondIdx(25);
                    }}
                    onClickUpPopShow={() => {
                      onClickUpPopShow(
                        isNewTrademark
                          ? fileType.is_case_file1
                          : fileType.reject
                      );
                      setSecondIdx(25);
                    }}
                  />
                  <SelectRow
                    title="최종심결"
                    content={selectTagName.caseReversedName}
                    onChange={(e) =>
                      setTradeMark({
                        ...tradeMark,
                        case_reversed_final_trial_state: e.target.value,
                      })
                    }
                    conditions={tradeMark.rejectEditing}
                    contentIdx={tradeMark.case_reversed_final_trial_state}
                    selectTag={judgmentStateListTag}
                  />
                </div>
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

export default FormRejectOverseas;
