import Pagination from "../common/Pagination";
import moment from "moment";
import "./css/DistributionPop.scss";

const DistributionPop = ({
  closePatentPop,
  patents,
  setPatentPage,
  dataCondition,
  setViewData,
  setShowPopup,
}) => {
  const setType = (flag) => {
    let type = "";
    if (flag === "r") {
      type = "권리성";
    } else if (flag === "t") {
      type = "기술";
    } else if (flag === "u") {
      type = "활용성";
    } else {
      type = "종합";
    }
    return type;
  };

  let tit;

  if (dataCondition.flag === "smart") {
    tit = `SMART5 ${setType(dataCondition.subFlag)} ${
      dataCondition.strValue
    } 등급`;
  } else if (dataCondition.flag === "inventor") {
    tit = `${dataCondition.strValue} 발명자`;
  } else if (dataCondition.flag === "status") {
    tit = `${dataCondition.strValue} 상태`;
  } else {
    tit =
      (dataCondition.year ? `${dataCondition.year}년 ` : "") +
      (dataCondition.strValue === "공개" ? "출원" : dataCondition.strValue) +
      (dataCondition.flag === "patent" ? "" : " 키워드");
  }

  return (
    <div id="DistributionPop">
      <strong>{tit} 특허</strong>
      <div className="tbl_box">
        <table>
          <colgroup>
            <col width={50} />
            <col width={50} />
            <col width={140} />
            <col width={120} />
            <col width={120} />
            <col width={120} />
            <col />
            <col width={100} />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>국가</th>
              <th>등록번호</th>
              <th>등록일</th>
              <th>출원번호</th>
              <th>출원일</th>
              <th style={{ textAlign: "left" }}>발명의 명칭</th>
              <th>등급</th>
            </tr>
          </thead>
          <tbody>
            {patents.list?.length ? (
              patents.list.map((patent, idx) => (
                <tr
                  key={patent.idx}
                  onClick={() => {
                    setViewData({
                      nation_string: patent.nation || "",
                      apply_number: patent.apply_no || "",
                      document_number: "",
                    });
                    setShowPopup(true);
                  }}
                >
                  <td>{10 * (patents.page - 1) + idx + 1}</td>
                  <td>{patent.nation || ""}</td>
                  <td>{patent.register_number || ""}</td>
                  <td>
                    {patent.register_at &&
                      moment.unix(patent.register_at).format("YYYY-MM-DD")}
                  </td>
                  <td>{patent.apply_no || ""}</td>
                  <td>
                    {patent.apply_at &&
                      moment.unix(patent.apply_at).format("YYYY-MM-DD")}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {patent.invention_title || ""}
                  </td>
                  <td>
                    <div className={`grade grade_${patent.sm_grade}`}>
                      {patent.sm_grade || ""}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        curPage={patents.page}
        lastNum={Math.ceil(patents.total_count / patents.count)}
        onClick={setPatentPage}
        disableMoveToTop={true}
      />
      <button
        className="close"
        onClick={() => {
          closePatentPop();
        }}
      >
        <i className="icon_exit_gray"></i>
      </button>
    </div>
  );
};

export default DistributionPop;
