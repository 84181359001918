import "./css/HistoryPopup.scss";
import DatePickerCustom from "../datepicker/DatePickerCustom";
import { useContext, useState } from "react";
import TradeMarkContext from "../../store/TradeMark";
import TradeMarkAPI from "../../API/trademark";
import { Link, useParams } from "react-router-dom";
const HistoryPopup = ({ setHistoryPopupShow, historyPopupIdx }) => {
  const tradeMark = useContext(TradeMarkContext);
  const { trademark_idx } = useParams();
  const [searchQueryHistorytitle, setSearchQueryHistorytitle] = useState("");
  const [searchResultHistorytitle, setSearchResultsHistorytitle] = useState([]);
  const [historyItem, setHistoryItem] = useState(
    tradeMark.history_list[historyPopupIdx]
  );

  const onClickSave = () => {
    tradeMark.setTradeMark({ ...tradeMark });
    setHistoryPopupShow(false);
  };
  const historyTitle = (e) => {
    const searchTerm = e.target.value;
    setSearchQueryHistorytitle(searchTerm);
    const encodedSearchTerm = searchTerm;
    TradeMarkAPI.searchTest(trademark_idx, "8", encodedSearchTerm)
      .then((response) => {
        setSearchResultsHistorytitle(response.data);
      })
      .catch((error) => {
        console.error("찾는중 오류 발생했습니다");
      });
  };
  const handleResultButtonClick3 = (historytitle) => {
    // Set the historyItem.title with the clicked data
    historyItem.title = historytitle.history;
    // Update the tradeMark state to reflect the change in historyItem.title
    tradeMark.setTradeMark({ ...tradeMark });
  };
  return (
    <div id="HistoryPopup">
      <header>
        <p>History 상세보기</p>
        <i
          className="icon_exit_gray"
          onClick={() => setHistoryPopupShow(false)}
        />
      </header>
      <section>
        <ul>
          <li>
            <p>업무일자</p>
            <div>
              {tradeMark.historyEditing ? (
                <DatePickerCustom
                  data={historyItem.work_at}
                  onChangeDatePicker={(e) => {
                    historyItem.work_at = new Date(e).getTime();
                    tradeMark.setTradeMark({ ...tradeMark });
                  }}
                />
              ) : (
                <p>{window.$Global.convertDate(historyItem.work_at)}</p>
              )}
            </div>
          </li>
          <li>
            <p>지시요청기한</p>
            <div>
              {tradeMark.historyEditing ? (
                <DatePickerCustom
                  data={historyItem.instruction_deadline}
                  onChangeDatePicker={(e) => {
                    historyItem.instruction_deadline = new Date(e).getTime();
                    tradeMark.setTradeMark({ ...tradeMark });
                  }}
                />
              ) : (
                <p>
                  {window.$Global.convertDate(historyItem.instruction_deadline)}
                </p>
              )}
            </div>
          </li>
          <li>
            <p>법정기한</p>
            <div>
              {tradeMark.historyEditing ? (
                <DatePickerCustom
                  data={historyItem.legal_deadline}
                  onChangeDatePicker={(e) => {
                    historyItem.legal_deadline = new Date(e).getTime();
                    tradeMark.setTradeMark({ ...tradeMark });
                  }}
                />
              ) : (
                <p>{window.$Global.convertDate(historyItem.legal_deadline)}</p>
              )}
            </div>
          </li>
          <li>
            <p>업무제목</p>
            <input
              type="text"
              value={historyItem.title}
              onChange={(e) => {
                historyItem.title = e.target.value;
                tradeMark.setTradeMark({ ...tradeMark });
                historyTitle(e);
              }}
              readOnly={!tradeMark.historyEditing}
            />
            {tradeMark.editingsed && (
              <ul className="dropdown">
                {searchResultHistorytitle.map((historytitle) => (
                  <li key={historytitle.id}>
                    <button
                      onClick={() => handleResultButtonClick3(historytitle)}
                    >
                      {historytitle.history}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li>
            <p>코멘트</p>
            <textarea
              value={historyItem.content}
              onChange={(e) => {
                historyItem.content = e.target.value;
                tradeMark.setTradeMark({ ...tradeMark });
              }}
              readOnly={!tradeMark.historyEditing}
            />
          </li>
        </ul>
      </section>
      <footer>
        <button onClick={onClickSave}>저장하기</button>
      </footer>
    </div>
  );
};

export default HistoryPopup;
