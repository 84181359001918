import React from "react";

function InventorInputRow({
  rowClassName,
  title,
  titleClass,
  condition,
  content,
  contetClassName,
  inputClassName,
  onChange,
  index,
  setKey,
}) {
  return (
    <div className={`row ${rowClassName || ""}`}>
      <h2 className={`title ${titleClass || ""}`}>
        {title}
        <i
          className="icon_info"
          data-content={window.$Global.getInfoMessage(title)}
        />
      </h2>
      {condition ? (
        <input
          type="text"
          className={inputClassName || ""}
          value={content || ""}
          onChange={(e) => onChange(index, setKey, e.target.value)}
          style={{ width: 135 }}
        />
      ) : (
        <p className={contetClassName || ""}>{content ? content : "N/A"}</p>
      )}
    </div>
  );
}
export default InventorInputRow;
